import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdLocalShipping } from "react-icons/md";

const features = [
    {
        name: "Effortless Order Tracking:",
        description:
            "Stay on top of every order with our comprehensive tracking system. Monitor the status of inbound, inventory, and outbound orders in one unified dashboard, ensuring you never miss a beat.",
        icon: FaLocationDot,
    },
    {
        name: "Streamlined Shipment Management:",
        description:
            "Simplify your shipment process by grouping outbound orders into shipments. Easily manage box counts, shipment details, and keep your operations running smoothly.",
        icon: MdLocalShipping,
    },
    {
        name: "Automated Invoicing:",
        description:
            "Simplify your billing with our automated invoicing feature. As shipments are processed, invoices are generated and updated in real-time, giving you accurate and timely billing without the hassle.",
        icon: FaFileInvoiceDollar,
    },
];

export default function DashboardFeatures() {
    return (
        <div className="overflow-hidden bg-white pt-36 pb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Dashboard Features
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div
                                        key={feature.name}
                                        className="relative pl-9"
                                    >
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon
                                                aria-hidden="true"
                                                className="absolute left-1 top-1 h-5 w-5 text-accent"
                                            />
                                            {feature.name}
                                        </dt>{" "}
                                        <dd className="inline">
                                            {feature.description}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <img
                        alt="Product screenshot"
                        src="/img-dashboard2.png"
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    />
                </div>
            </div>
        </div>
    );
}
