/** @format */

import { sortableColumns } from "../../hooks/useSort";

// export default function ColumnSort({
//     header,
//     sortConfig,
// }: {
//     header: {
//         key: string;
//     };
//     sortConfig: {
//         key: string;
//         order: string;
//     };
// }) {
//     return (
//         <>
//             {sortableColumns.includes(header.key) && (
//                 <span className="ml-2 cursor-pointer">
//                     {sortConfig.key === header.key ? (
//                         sortConfig.order === "asc" ? (
//                             <span className="hover:text-gray-500 text-white">
//                                 ↑
//                             </span>
//                         ) : (
//                             <span className="text-gray-500">↓</span>
//                         )
//                     ) : (
//                         // <span className="text-gray-400">▲▼</span>
//                         <></>
//                     )}
//                 </span>
//             )}
//         </>
//     );
// }

export default function ColumnSort({
    header,
    sortConfig,
}: {
    header: {
        key: string;
    };
    sortConfig: {
        key: string;
        order: string;
    };
}) {
    return (
        <>
            {sortableColumns.includes(header.key) && (
                <span className="ml-2 cursor-pointer">
                    {sortConfig.key === header.key ? (
                        // Show arrow for the sorted column
                        sortConfig.order === "asc" ? (
                            <span className="text-gray-500">↑</span>
                        ) : (
                            <span className="text-gray-500">↓</span>
                        )
                    ) : (
                        // Hide arrows for unsorted columns until hovered
                        <span className="text-gray-400 opacity-0 group-hover:opacity-100 transition-all">
                            ↑
                        </span>
                    )}
                </span>
            )}
        </>
    );
}
