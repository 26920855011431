import Cookies from "js-cookie";
import { buildUrl } from "../../../../utils/url/buildUrl";



export const fetchTrackings = async (searchParams: URLSearchParams, type?: string) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/trackings`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            type: type ? type : "inventory"
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}