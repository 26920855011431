/** @format */

import { useEffect, useState } from "react";
import { PaginationLink } from "../../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../../utils/pagination/paginationResults";
import { TrackingArrived } from "../../../../../components/trackingArrived";
import {
    convertDateToEST,
    formatDateFromDb,
} from "../../../../../utils/date/date_formatter";
import { calculateOffset } from "../../../../../utils/pagination/calculateOffset";
import LoadingWheel from "../../../../../components/loadingWheel";
import Results from "../../../../../models/results";
import { fetchTrackings } from "../../api/trackingsApi";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DeleteTrackingModal from "./deleteTrackingModal";
import { AdminLimitSelector } from "../../../../../utils/pagination/limitSelector";

interface AccountTrackingsProps {
    type?: string;
}

function AccountTrackings({ type }: AccountTrackingsProps) {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();

    const [trackingsArrived, setTrackingsArrived] = useState<TrackingArrived[]>(
        []
    );
    const [trackingsArrivedResults, setTrackingsArrivedResults] =
        useState<Results>({
            showing_from: 0,
            showing_to: 0,
            total_results: 0,
            current_page: 0,
        });

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchTrackings(user_id, offset, limit, type);
            if (data.status === "success") {
                setTrackingsArrived(data.data.trackings);
                setTrackingsArrivedResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        };
        fetchData();
    }, [offset, refetchData, limit]);

    const tableHeaders = [
        {
            key: type === "inventory" || !type ? "date_arrived" : "date",
            label: type === "inventory" || !type ? "Date Arrived" : "Date Sent",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking",
            label: "Tracking number",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "carrier",
            label: "Detected Carrier",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking_url",
            label: "URL",
            className: "sm:min-w-32 min-w-28",
        },
    ];

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    useEffect(() => {
        if (selectedTrackingId) {
            setOpenDeleteModal(true);
        }
    }, [selectedTrackingId]);

    useEffect(() => {
        if (!openDeleteModal) {
            setSelectedTrackingId(null);
        }
    }, [openDeleteModal]);

    return (
        <div className="flex flex-col pt-3">
            {!loading && trackingsArrived && trackingsArrived.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            className={`py-2 ${header.className}`}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                    {type === "inventory" || !type ? (
                                        <th
                                            key="delete"
                                            className={`py-2 text-right`}
                                        >
                                            Delete
                                        </th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {trackingsArrived.map((tracking) => (
                                    <tr
                                        key={tracking.tracking}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        <td className="py-2.5">
                                            {convertDateToEST(tracking.date)}
                                        </td>
                                        <td className="">
                                            {tracking.tracking}
                                        </td>
                                        <td className="">{tracking.carrier}</td>
                                        {tracking.tracking_url !== "Unknown" ? (
                                            <td className="">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={tracking.tracking_url}
                                                    className="text-accent"
                                                >
                                                    View
                                                </a>
                                            </td>
                                        ) : (
                                            <td>Undetermined</td>
                                        )}
                                        {type === "inventory" || !type ? (
                                            <td
                                                onClick={() =>
                                                    setSelectedTrackingId(
                                                        tracking.tracking_id
                                                    )
                                                }
                                                className="text-right whitespace-nowrap pr-2 cursor-pointer"
                                            >
                                                <TrashIcon className="w-5 h-5 text-red-500 inline-block" />
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults
                                    results={trackingsArrivedResults}
                                />
                                <AdminLimitSelector
                                    limit={limit}
                                    setLimit={setLimit}
                                    setOffset={setOffset}
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset - 1)
                                }
                                disabled={offset === 0}
                                className={`${
                                    offset === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset + 1)
                                }
                                disabled={
                                    trackingsArrivedResults.showing_to >=
                                    trackingsArrivedResults.total_results
                                }
                                className={`${
                                    trackingsArrivedResults.showing_to >=
                                    trackingsArrivedResults.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no trackings added
                        </span>
                    )}
                </div>
            )}

            <DeleteTrackingModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                id={selectedTrackingId}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default AccountTrackings;
