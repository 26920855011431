/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import LoadingWheel from "../../../components/loadingWheel";
import ClientOutboundTable from "../../admin/3plclients/client-data/shipments/client-orders-data/clientOutboundTable";
import { fetchOutboundOrders, fetchShipmentAndOrders } from "./api/outboundApi";
import Shipment from "../../../models/shipments";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import { useNotification } from "../../../utils/notification/notificationContext";
import { ShipmentTracking } from "../../../models/shipments";
import ShipmentTrackingsTable from "../../../components/tables/shipmentTrackings";
import { Order } from "../../../models/order";
import Results from "../../../models/results";

export default function OutboundOrdersModal({
    shipmentLoaded,
    openOutboundOrders,
    setOpenOutboundOrders,
    shipment_id,
}: {
    shipmentLoaded: Shipment | null;
    openOutboundOrders: boolean;
    setOpenOutboundOrders: React.Dispatch<React.SetStateAction<boolean>>;
    shipment_id?: string | null;
}) {
    const { user_id, invoice_id } = useParams();
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [outboundOrders, setOutboundOrders] = useState<Order[]>([]);
    const [shipmentTrackings, setShipmentTrackings] = useState<
        ShipmentTracking[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [shipment, setShipment] = useState<Shipment | null>(null);

    const [offset, setOffset] = useState(0);

    const [fullLoading, setFullLoading] = useState(false);

    const shipmentInfo = [
        {
            label: "Shipment Name",
            value: shipment?.name,
        },
        {
            label: "Date",
            value: formatDateFromDb(shipment?.date ? shipment?.date : ""),
        },
        {
            label: "Notes",
            value: shipment?.notes,
        },
        {
            label: "Boxes",
            value: `${shipment?.small_boxes} small boxes`,
            value2: `${shipment?.medium_boxes} medium boxes`,
            value3: `${shipment?.large_boxes} large boxes`,
        },
    ];

    const requestedShipmentInfo = [
        {
            label: "Shipment Name",
            value: shipment?.name,
        },
        {
            label: "Date",
            value: formatDateFromDb(shipment?.date ? shipment?.date : ""),
        },
        {
            label: "Notes",
            value: shipment?.notes,
            value2: null,
            value3: null,
        },
    ];

    useEffect(() => {
        if (!openOutboundOrders) {
            setTimeout(() => {
                setShipment(null);
            }, 250);
        }
    }, [openOutboundOrders]);

    useEffect(() => {
        if (openOutboundOrders || offset) {
            if (shipmentLoaded) {
                const fetchData = async () => {
                    setLoading(true);
                    const data = await fetchOutboundOrders(
                        shipmentLoaded?.shipment_id
                            ? shipmentLoaded?.shipment_id
                            : "",
                        offset
                    );
                    if (data.status === "success") {
                        setOutboundOrders(data.data.outbound);
                        setShipmentTrackings(data.data.trackings);
                        setResults(data.data.results);
                    } else {
                        showNotification(
                            "Failed fetching data",
                            data.message,
                            "error"
                        );
                    }
                    setLoading(false);
                };
                setShipment(shipmentLoaded);
                fetchData();
            } else {
                const fetchData = async () => {
                    setFullLoading(true);
                    const data = await fetchShipmentAndOrders(
                        shipment_id || "",
                        offset,
                        user_id
                    );
                    if (data.status === "success") {
                        setShipment(data.data.shipment);
                        setOutboundOrders(data.data.outbound);
                        setShipmentTrackings(data.data.trackings);
                        setResults(data.data.results);
                    } else {
                        showNotification(
                            "Failed fetching data",
                            data.message,
                            "error"
                        );
                    }
                    setFullLoading(false);
                };
                fetchData();
            }
        }
    }, [openOutboundOrders, offset]);

    return (
        <Dialog
            open={openOutboundOrders}
            onClose={() => setOpenOutboundOrders(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenOutboundOrders(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Shipment information
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            {!fullLoading ? (
                                <div>
                                    <dl className="grid grid-cols-1 sm:grid-cols-4 pb-4">
                                        {(shipment?.status === "shipped"
                                            ? shipmentInfo
                                            : requestedShipmentInfo
                                        ).map((info) => (
                                            <div
                                                key={info.label}
                                                className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0"
                                            >
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    {info.label}
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                                    {info.value}
                                                </dd>
                                                {info.value2 ? (
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                                                        {info.value2}
                                                    </dd>
                                                ) : (
                                                    <></>
                                                )}
                                                {info.value3 ? (
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                                                        {info.value3}
                                                    </dd>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        ))}
                                    </dl>
                                    <span className="font-semibold text-base text-gray-900">
                                        Shipment contents
                                    </span>
                                    <div className="pb-3"></div>
                                    {!loading ? (
                                        <>
                                            <ClientOutboundTable
                                                outboundOrders={outboundOrders}
                                                results={results}
                                                offset={offset}
                                                setOffset={setOffset}
                                            />
                                            <div className="py-2">
                                                <div className="h-px w-full bg-gray-300"></div>
                                            </div>
                                            {shipmentTrackings &&
                                            shipmentTrackings.length > 0 ? (
                                                <ShipmentTrackingsTable
                                                    trackings={
                                                        shipmentTrackings
                                                    }
                                                />
                                            ) : (
                                                <span className="text-sm text-gray-600">
                                                    No trackings on this
                                                    shipment
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <div className="flex flex-grow items-center pt-44 justify-center">
                                            <LoadingWheel />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
