/** @format */

import ClientSideBar from "../../../components/client/sideBar";
import ShipmentsTable from "./shipmentsTable";
import Header from "../../../components/header";
import { fetchShipments } from "./api/outboundApi";
import { useEffect, useState } from "react";
import Shipment from "../../../models/shipments";
import Results from "../../../models/results";
import { useNotification } from "../../../utils/notification/notificationContext";
import OutboundPageSelector from "./outboundPageSelector";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ShipmentPageSelector } from "../../../models/pageSelectors";
import OutboundTrackingsTable from "./outboundTrackingsTable";
import { usePage } from "../../../hooks/usePage";
import { useSort } from "../../../hooks/useSort";

function OutboundPage() {
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [shipments, setShipments] = useState<Shipment[]>([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const shipmentPageTypes: ShipmentPageSelector[] = [
        "shipped",
        "requested",
        "trackings",
    ];
    const { pageSelected, handlePageChange } = usePage(
        shipmentPageTypes,
        "/outbound"
    );

    const fetchData = async () => {
        const data = await fetchShipments(searchParams, sortConfig);
        if (data.status === "success") {
            setShipments(data.data.shipments);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [pageSelected]);

    useEffect(() => {
        fetchData();
    }, [sortConfig]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto">
                    <span className="text-2xl font-bold">
                        Outbound Shipments
                    </span>

                    <OutboundPageSelector
                        pageSelected={pageSelected}
                        handlePageChange={handlePageChange}
                    />
                    <div className="pt-10"></div>
                    {pageSelected !== "trackings" ? (
                        <ShipmentsTable
                            shipments={shipments}
                            results={results}
                            loading={loading}
                            pageSelected={pageSelected}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            sortLoading={sortLoading}
                        />
                    ) : (
                        <OutboundTrackingsTable />
                    )}
                </div>
            </div>
        </div>
    );
}

export default OutboundPage;
