/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../../../components/loadingWheel";
import { useEffect, useState } from "react";
import { closeInvoicePeriod, editPastInvoice } from "../../../api/billingApi";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import { InputField } from "../../../../../../components/inputField";
import { Invoice } from "../../../../../../models/invoices";

type PaymentStatus = "paid" | "unpaid";

function EditPastInvoiceModal({
    openEditPastInvoiceModal,
    setOpenEditPastInvoiceModal,
    selectedPastInvoice,
    setSelectedPastInvoice,
    handleRefetch,
}: {
    openEditPastInvoiceModal: boolean;
    setOpenEditPastInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPastInvoice: Invoice | null;
    setSelectedPastInvoice: React.Dispatch<
        React.SetStateAction<Invoice | null>
    >;
    handleRefetch: () => void;
}) {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    const [invoicePaymentUrl, setInvoicePaymentUrl] = useState("");

    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>("unpaid");

    const handleEditPastInvoice = async () => {
        setLoading(true);
        const data = await editPastInvoice(
            user_id,
            selectedPastInvoice?.invoice_id,
            invoicePaymentUrl,
            paymentStatus
        );
        if (data.status === "success") {
            setOpenEditPastInvoiceModal(false);
            showNotification("Edited invoice.", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to edit invoice", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (openEditPastInvoiceModal) {
            setInvoicePaymentUrl(selectedPastInvoice?.invoice_url || "");
            setPaymentStatus(
                (selectedPastInvoice?.payment_status as PaymentStatus) ||
                    "unpaid"
            );
        } else {
            setSelectedPastInvoice(null);
        }
    }, [openEditPastInvoiceModal]);

    return (
        <Dialog
            open={openEditPastInvoiceModal}
            onClose={() => setOpenEditPastInvoiceModal(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenEditPastInvoiceModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Close invoice period
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <div className="flex flex-row space-x-4">
                                <button
                                    onClick={() => setPaymentStatus("unpaid")}
                                    className={`px-3 text-sm rounded-md h-9 ${
                                        paymentStatus === "unpaid"
                                            ? "bg-accent text-white"
                                            : "bg-gray-200 text-black"
                                    }`}
                                >
                                    Unpaid
                                </button>

                                <button
                                    onClick={() => setPaymentStatus("paid")}
                                    className={`px-3 text-sm rounded-md h-9 ${
                                        paymentStatus === "paid"
                                            ? "bg-accent text-white"
                                            : "bg-gray-200 text-black"
                                    }`}
                                >
                                    Paid
                                </button>
                            </div>
                            <InputField
                                label="Invoice payment url"
                                value={invoicePaymentUrl}
                                onChange={(e) =>
                                    setInvoicePaymentUrl(e.target.value)
                                }
                                placeholder=""
                                optional={true}
                            />
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                disabled={loading}
                                onClick={() => handleEditPastInvoice()}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Save changes
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenEditPastInvoiceModal(false)
                                }
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default EditPastInvoiceModal;
