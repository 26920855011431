/** @format */
import Cookies from "js-cookie";

export const fetchDashboardData = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/statistics`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();
        return data;

    } catch (error) {
        return { data: {status: "failed"} }
    }
};

export const updateTax = async (
    tax_rate: string,
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account/tax`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({tax_rate})
            }
        );

        const data = await response.json()
        return data;
        
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}
