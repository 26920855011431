

export function formatMoney(amount: number | undefined) {
    if (amount) {
        return `$${(amount / 100).toFixed(2)}`
    }
    if (amount === 0) {
        return `$${amount}`
    }
    return null
}

export function formatInvoiceMoney(amount: number | undefined) {
    if (amount === 0) {
        return "$0.00"
    }
    if (amount) {
        return `$${(amount / 100).toFixed(2)}`
    }
    return null
}