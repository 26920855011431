/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useCallback, useRef } from "react";
import LoadingWheel, { SortLoading } from "../../../components/loadingWheel";
import Results from "../../../models/results";
import { fetchInboundOrders } from "./api/inboundApi";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { EditOrderModal } from "./inbound-data/editOrderModal";
import { EmptyInboundTable } from "../../../components/emptyStates";
import { StatusTag } from "../../../components/statusTags";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import { formatMoney } from "../../../utils/price/formatMoney";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import { useCheckInDatesModal } from "../../../hooks/useCheckInDates";
import CheckInDatesModal from "../../../components/checkInDatesModal";
import { useTrackingNumbersModal } from "../../../hooks/useTrackingNumbers";
import TrackingNumbersModal from "../../../components/trackingNumbersModal";
import { Order } from "../../../models/order";
import { capitalizeFirstLetter } from "../../../utils/strings/string";
import LimitSelector from "../../../utils/pagination/limitSelector";
import { sortableColumns, useSort } from "../../../hooks/useSort";
import useScrollTranslation from "../../../hooks/useScrollTranslation";
import ColumnSort from "../../../utils/sort/columnSort";
import { head } from "lodash";

export default function InboundTable({
    inboundOrders,
    results,
    handleRefetch,
    setOpenAddOrderModal,
    sortConfig,
    toggleSort,
    sortLoading,
}: {
    inboundOrders: Order[];
    results: Results;
    handleRefetch: () => void;
    setOpenAddOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    sortConfig: {
        key: string;
        order: string;
    };
    toggleSort: (key: string) => void;
    sortLoading: boolean;
}) {
    const [searchParams] = useSearchParams();

    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedInboundOrder, setSelectedInboundOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInboundOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInboundOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInboundOrder(null);
        }
    }, [openEditModal]);

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const tableHeaders = [
        { key: "status", label: "Status", className: "sm:min-w-32 min-w-32" },
        {
            key: "fulfilled_by",
            label: "Fulfilled by",
            className: "sm:min-w-32 min-w-32",
        },
        { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
        { key: "retailer", label: "Retailer", className: "min-w-32" },
        { key: "order_number", label: "Order #", className: "min-w-32" },
        {
            key: "item_name",
            label: "Item Name",
            className: "sm:min-w-48 min-w-48",
        },
        {
            key: "brand_sku",
            label: "Brand SKU",
            className: "sm:min-w-32 min-w-28",
        },
        { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
        {
            key: "quantity",
            label: "Quantity",
            className: "sm:min-w-32 min-w-28",
        },
        { key: "color", label: "Color", className: "sm:min-w-32 min-w-28" },
        { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28" },
        {
            key: "amazon_sku",
            label: "Amazon SKU",
            className: "sm:min-w-32 min-w-28",
        },
        {
            key: "unit_cost",
            label: "Unit cost",
            className: "sm:min-w-32 min-w-28",
        },
        {
            key: "list_price",
            label: "List price",
            className: "sm:min-w-32 min-w-28",
        },
        {
            key: "trackings",
            label: "Trackings",
            className: "sm:min-w-32 min-w-28",
        },
        { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
    ];

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div>
            {inboundOrders && inboundOrders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            onClick={() =>
                                                toggleSort(header.key)
                                            }
                                            key={header.key}
                                            className={`group hover:bg-gray-100 py-2 cursor-pointer ${header.className}`}
                                        >
                                            {header.label}{" "}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {inboundOrders.map((order) => (
                                    <tr
                                        key={order.order_id}
                                        onClick={() =>
                                            setSelectedInboundOrder(order)
                                        }
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-2.5 pr-4 whitespace-nowrap">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {sortLoading ? (
                                                <div className="w-full h-4 bg-gray-300 shimmer animate-shimmer rounded mb-2"></div>
                                            ) : (
                                                <>
                                                    {capitalizeFirstLetter(
                                                        order.fulfilled_by
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td className="">{order.quantity}</td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td className="">
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleViewTrackings(
                                                    order.trackings
                                                );
                                            }}
                                            className="text-accent"
                                        >
                                            View
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-2">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={`/inbound?offset=${calculateOffset(
                                    searchParams,
                                    "back"
                                )}`}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={`/inbound?offset=${calculateOffset(
                                    searchParams,
                                    "forward"
                                )}`}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <EmptyInboundTable
                        setOpenAddOrderModal={setOpenAddOrderModal}
                    />
                </div>
            )}
            <EditOrderModal
                inboundId={selectedInboundOrder?.order_id}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
            />

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />
        </div>
    );
}
