/** @format */

import { useState, useEffect } from "react";
import Header from "../../../components/header";
import DashboardCards from "./dashboardCards";
import { AdminDashboardModel } from "../../../models/adminDashboard";
import { fetchDashboardData } from "./api/dashboardApi";
import LoadingWheel from "../../../components/loadingWheel";
import AdminSideBar from "../../../components/admin/sideBar";
import BoxPrices from "../3plclients/settings/viewBoxPrices";
import { useNotification } from "../../../utils/notification/notificationContext";

export default function AdminDashboardPage() {
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] =
        useState<AdminDashboardModel | null>(null);

    const { showNotification } = useNotification();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchDashboardData();
            if (data.status === "success") {
                setDashboardData(data.data);
            } else {
                showNotification("Failed to fetch data", data.message, "error");
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-row">
            <AdminSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="sm:mx-10 mx-4 pt-10 flex flex-col">
                    <span className="text-2xl font-bold">Dashboard</span>

                    {!loading ? (
                        <div>
                            <DashboardCards dashboardData={dashboardData} />
                        </div>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
