/** @format */

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export default function ResetPasswordSuccessPage() {
    return (
        <div>
            <div className="flex flex-col flex-grow h-screen sm:justify-center items-center pb-36 sm:pt-0 pt-20">
                <IoIosCheckmarkCircleOutline className="w-44 h-44 text-[#42D059] pb-3" />
                <span className="text-2xl pb-3 font-normal">
                    Password Changed!
                </span>
                <span className="text-base font-light mx-4">
                    Your password has been changed successfully.
                </span>
                <p className="mt-10 text-center text-sm text-gray-500">
                    Continue by going to{" "}
                    <a
                        href="/login"
                        className="font-semibold leading-6 text-accent hover:text-accenthighlight"
                    >
                        Login
                    </a>
                </p>
            </div>
        </div>
    );
}
