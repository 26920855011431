/** @format */

import { useEffect, useState } from "react";
import ViewBoxPrices from "./settings/viewBoxPrices";
import { fetchSettings } from "./api/settingsApi";
import { BoxPrices, WarehouseAddress } from "../../../models/adminDashboard";
import ManageAdminAccounts from "./settings/manageAdminAccount";
import { AdminAccountData } from "../../../models/accountData";
import LoadingWheel from "../../../components/loadingWheel";
import ManageAddress from "./settings/manageAddress";

function ClientSettings() {
    const [loading, setLoading] = useState(false);
    const [boxPrices, setBoxPrices] = useState<BoxPrices | null>(null);
    const [adminAccounts, setAdminAccounts] = useState<AdminAccountData[]>([]);
    const [warehouseAddress, setWarehouseAddress] =
        useState<WarehouseAddress | null>(null);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchSettings();
            if (data.status === "success") {
                setBoxPrices(data.data.prices);
                setAdminAccounts(data.data.accounts);
                setWarehouseAddress(data.data.address);
            } else {
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex pb-10">
            {!loading ? (
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                        <ViewBoxPrices boxPrices={boxPrices} />
                        <ManageAdminAccounts
                            adminAccounts={adminAccounts}
                            handleRefetch={handleRefetch}
                        />
                    </div>
                    <div>
                        <ManageAddress
                            warehouseAddress={warehouseAddress}
                            handleRefetch={handleRefetch}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-col flex-grow items-center justify-center pt-44">
                    <LoadingWheel />
                </div>
            )}
        </div>
    );
}

export default ClientSettings;
