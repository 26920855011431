/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../components/client/sideBar";
import InventoryTable from "./inventory-data/inventoryTable";
import Header from "../../../components/header";
import { fetchInventoryOrders } from "./api/inventoryApi";
import Results from "../../../models/results";
import { useNotification } from "../../../utils/notification/notificationContext";
import { Order } from "../../../models/order";
import InventoryPageSelector from "./inventoryPageSelector";
import { TrackingArrived } from "../../../components/trackingArrived";
import { fetchTrackings } from "./api/trackingsApi";
import TrackingsArrivedTable from "./tracking-data/trackingsTable";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { InventoryPageType } from "../../../models/pageSelectors";
import { RequestOutboundShipmentModal } from "./inventory-data/request-shipment/requestOutboundShipmentModal";
import { Limit } from "../../../utils/pagination/limitSelector";
import { usePage } from "../../../hooks/usePage";
import { useSort } from "../../../hooks/useSort";

function InventoryPage() {
    const { showNotification } = useNotification();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);
    const [trackingsLoading, setTrackingsLoading] = useState(true);

    const [inventoryOrders, setInventoryOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [trackingsArrived, setTrackingsArrived] = useState<TrackingArrived[]>(
        []
    );
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const inboundPageTypes: InventoryPageType[] = ["units", "trackings"];
    const { pageSelected, handlePageChange } = usePage(
        inboundPageTypes,
        "/inventory"
    );

    const fetchData = async () => {
        const data = await fetchInventoryOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setInventoryOrders(data.data.inventory);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        setSortLoading(true);
        fetchData();
    }, [sortConfig]);

    const hasFetchedTrackings = useRef(false); // Create a ref to track if data has been fetched

    useEffect(() => {
        if (pageSelected === "trackings" && !hasFetchedTrackings.current) {
            const fetchData = async () => {
                setTrackingsLoading(true);
                const data = await fetchTrackings(searchParams);
                if (data.status === "success") {
                    setTrackingsArrived(data.data.trackings);
                    setTrackingsResults(data.data.results);
                    hasFetchedTrackings.current = true; // Set ref to true after fetching
                } else {
                    showNotification(
                        "Failed fetching data",
                        data.message,
                        "error"
                    );
                }
                setTrackingsLoading(false);
            };
            fetchData();
        }
    }, [pageSelected]);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [
        openRequestOutboundShipmentModal,
        setOpenRequestOutboundShipmentModal,
    ] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-row items-center justify-between">
                        <span className="text-2xl font-bold">Inventory</span>
                        <button
                            disabled={selectedOrders.length < 1}
                            onClick={
                                selectedOrders.length > 0 &&
                                selectedOrders[0].fulfilled_by == "merchant"
                                    ? () =>
                                          setOpenRequestOutboundShipmentModal(
                                              true
                                          )
                                    : () =>
                                          showNotification(
                                              "Do not select amazon fulfilled orders.",
                                              "You can only select merchant for requests.",
                                              "error"
                                          )
                            }
                            className={`${
                                selectedOrders.length < 1
                                    ? "bg-accentdim"
                                    : "bg-accent hover:bg-accenthighlight"
                            } text-white font-medium px-5 text-sm rounded-md h-8`}
                        >
                            Request Outbound Shipment
                        </button>
                    </div>

                    <InventoryPageSelector
                        pageSelected={pageSelected}
                        handlePageChange={handlePageChange}
                    />

                    <div className="pt-10"></div>

                    {pageSelected === "units" ? (
                        <InventoryTable
                            inventoryOrders={inventoryOrders}
                            results={results}
                            loading={loading}
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            handleRefetch={handleRefetch}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            sortLoading={sortLoading}
                        />
                    ) : (
                        <TrackingsArrivedTable
                            trackings={trackingsArrived}
                            results={trackingsResults}
                            loading={trackingsLoading}
                            handleRefetch={handleRefetch}
                        />
                    )}
                </div>
            </div>

            <RequestOutboundShipmentModal
                selectedOrders={selectedOrders}
                openRequestOutboundShipmentModal={
                    openRequestOutboundShipmentModal
                }
                setOpenRequestOutboundShipmentModal={
                    setOpenRequestOutboundShipmentModal
                }
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default InventoryPage;
