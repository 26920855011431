import Cookies from "js-cookie";
import { Order } from "../../../../models/order";
import { ScannedBarcode } from "../../../../models/scannedBarcode";
import { buildUrl } from "../../../../utils/url/buildUrl";
import { off } from "process";


export const createShipment = async (
    user_id: string | undefined,
    name: string,
    date: string,
    notes: string,
    smallBoxes: string,
    mediumBoxes: string,
    largeBoxes: string,
    ordersPayload: { orderId: string; quantityShipped: string; }[],
    scannedBarcodes: ScannedBarcode[]
) => {

    try {
        const jsonBody = {
            "user_id": user_id,
            "name": name,
            "date": date,
            "notes": notes,
            "small_boxes": smallBoxes,
            "medium_boxes": mediumBoxes,
            "large_boxes": largeBoxes,
            "inventory_orders": ordersPayload,
            "trackings": scannedBarcodes
        }
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();

        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const deleteShipment = async (
    shipment_id: string | undefined
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/` + shipment_id,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const fetchAllShipmentTrackings = async (user_id: string | undefined, offset: number, limit: number) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/trackings/${user_id}`;
        const params = {
            offset: String(offset),
            limit: limit && limit != 0 ? String(limit) : "10"
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const updateRequestedShipmentStatus = async (
    shipment_id: string | undefined
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/` + shipment_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 