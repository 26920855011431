import Cookies from "js-cookie";



export const fetchInvoices = async (
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/invoices",
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}

export const fetchInvoiceData = async (
    invoice_id: string | undefined,
    user_id?: string | undefined
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/v1/invoices/${invoice_id}${user_id ? `?user_id=${encodeURIComponent(user_id || "")}` : ""}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}