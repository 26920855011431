/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { fetchClientOrders } from "../../../api/unitsApi";
import { useParams } from "react-router-dom";
import LoadingWheel from "../../../../../../components/loadingWheel";
import ClientOutboundTable from "../client-orders-data/clientOutboundTable";
import Shipment, { ShipmentTracking } from "../../../../../../models/shipments";
import {
    convertDateToEST,
    formatDateFromDb,
} from "../../../../../../utils/date/date_formatter";
import { Order } from "../../../../../../models/order";
import {
    deleteShipment,
    updateRequestedShipmentStatus,
} from "../../../api/shipmentsApi";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import ShipmentTrackingsTable from "../../../../../../components/tables/shipmentTrackings";
import { downloadShipmentPdf } from "../../../api/labelsApi";
import Results from "../../../../../../models/results";
import { getAdminType } from "../../../../../../utils/adminTypes/actions";

export default function OutboundOrdersModal({
    shipment,
    openOutboundOrders,
    setOpenOutboundOrders,
    handleFullRefetch,
}: {
    shipment: Shipment | null;
    openOutboundOrders: boolean;
    setOpenOutboundOrders: React.Dispatch<React.SetStateAction<boolean>>;
    handleFullRefetch: () => void;
}) {
    const isOwner = getAdminType() === "owner";
    const { user_id } = useParams();
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const [outboundOrders, setOutboundOrders] = useState<Order[]>([]);
    const [shipmentTrackings, setShipmentTrackings] = useState<
        ShipmentTracking[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });
    const [offset, setOffset] = useState(0);

    const [downloadLoading, setDownloadLoading] = useState(false);

    const [shipmentName, setShipmentName] = useState("");

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const data = await fetchClientOrders(
                user_id,
                "outbound",
                shipment?.shipment_id,
                offset
            );
            if (data.status === "success") {
                setOutboundOrders(data.data.orders);
                setShipmentTrackings(data.data.trackings);
                setResults(data.data.results);
            }
            setLoading(false);
        };
        if (openOutboundOrders || offset) {
            fetchData();
        }
    }, [openOutboundOrders, offset]);

    const handleDeleteShipment = async () => {
        setDeleteLoading(true);
        const data = await deleteShipment(shipment?.shipment_id);
        if (data.status === "success") {
            setOpenOutboundOrders(false);
            showNotification(
                "Deleted shipment",
                "Orders returned to inventory",
                "success"
            );
            handleFullRefetch();
        } else {
            showNotification(
                "Failed to deleted shipment",
                data.message,
                "error"
            );
        }
        setDeleteLoading(false);
    };

    const handleDownload = async () => {
        setDownloadLoading(true);
        const success = await downloadShipmentPdf(shipment?.shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf", "", "error");
        }
        setDownloadLoading(false);
    };

    const handleToggleStatus = async () => {
        setStatusLoading(true);
        const data = await updateRequestedShipmentStatus(shipment?.shipment_id);
        if (data.status === "success") {
            showNotification("Updated status of shipment", "", "success");
            setOpenOutboundOrders(false);
            handleFullRefetch();
        } else {
            showNotification("Failed to update status", data.message, "error");
        }
        setStatusLoading(false);
    };

    return (
        <Dialog
            open={openOutboundOrders}
            onClose={() => setOpenOutboundOrders(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenOutboundOrders(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Shipment information
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            <dl className="grid grid-cols-1 sm:grid-cols-4 pb-4">
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Shipment Name
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {shipment?.name}
                                    </dd>
                                </div>
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Date
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {formatDateFromDb(shipment?.date || "")}
                                    </dd>
                                </div>
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Notes
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {shipment?.notes}
                                    </dd>
                                </div>
                                {shipment?.status === "shipped" ? (
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Boxes
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                            {shipment?.small_boxes} small boxes
                                        </dd>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                                            {shipment?.medium_boxes} medium
                                            boxes
                                        </dd>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700">
                                            {shipment?.large_boxes} large boxes
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Attached labels
                                        </dt>
                                        <dd
                                            onClick={() => handleDownload()}
                                            className="mt-1 text-sm leading-6 sm:mt-1 text-accent cursor-pointer"
                                        >
                                            {downloadLoading ? (
                                                <LoadingWheel size="small" />
                                            ) : (
                                                <>Download</>
                                            )}
                                        </dd>
                                    </div>
                                )}
                            </dl>
                            <span className="font-semibold text-base text-gray-900">
                                Shipment contents
                            </span>
                            <div className="pb-3"></div>
                            {!loading ? (
                                <>
                                    <ClientOutboundTable
                                        outboundOrders={outboundOrders}
                                        results={results}
                                        offset={offset}
                                        setOffset={setOffset}
                                    />
                                    <div className="py-2">
                                        <div className="h-px w-full bg-gray-300"></div>
                                    </div>
                                    {shipmentTrackings &&
                                    shipmentTrackings.length > 0 ? (
                                        <ShipmentTrackingsTable
                                            trackings={shipmentTrackings}
                                        />
                                    ) : (
                                        <span className="text-sm text-gray-600">
                                            No trackings on this shipment
                                        </span>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex justify-between">
                            {/* {isOwner ? ( */}
                            <button
                                onClick={() => handleDeleteShipment()}
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                            >
                                {deleteLoading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Delete
                            </button>
                            {/* ) : null} */}
                            {shipment?.status === "requested" ? (
                                <>
                                    {!shipment?.admin_status ? (
                                        <button
                                            onClick={() => handleToggleStatus()}
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto items-center"
                                        >
                                            {statusLoading ? (
                                                <span className="pr-2 items-center flex flex-row justify-center">
                                                    <LoadingWheel
                                                        size="small"
                                                        color="white"
                                                    />
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                            Mark shipped
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => handleToggleStatus()}
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                        >
                                            {statusLoading ? (
                                                <span className="pr-2 items-center flex flex-row justify-center">
                                                    <LoadingWheel size="small" />
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                            Mark unshipped
                                        </button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
