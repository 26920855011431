/** @format */

import { useState } from "react";
import { PaymentStatusTag } from "../../../components/statusTags";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { useNavigate, useSearchParams } from "react-router-dom";
import Results from "../../../models/results";
import { Invoice } from "../../../models/invoices";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import { formatMoney } from "../../../utils/price/formatMoney";
import LimitSelector from "../../../utils/pagination/limitSelector";

function InvoicesTable({
    invoices,
    results,
}: {
    invoices: Invoice[];
    results: Results;
}) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const tableHeaders = [
        {
            key: "payment_status",
            label: "Payment status",
            className: "sm:min-w-32 min-w-32",
        },
        { key: "invoice_date", label: "Invoice date", className: "min-w-32" },
        {
            key: "invoice_period",
            label: "Invoice period",
            className: "sm:min-w-32 min-w-28",
        },
        { key: "total", label: "Total", className: "min-w-32" },
    ];

    return (
        <div>
            {invoices && invoices.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            className={`py-2 ${header.className}`}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr
                                        key={invoice.invoice_id}
                                        onClick={() =>
                                            navigate(
                                                "/billing/" + invoice.invoice_id
                                            )
                                        }
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-2.5">
                                            <PaymentStatusTag
                                                status={invoice.payment_status}
                                                value={
                                                    !invoice.invoice_url
                                                        ? "waiting for invoice url"
                                                        : ""
                                                }
                                            />
                                        </td>
                                        <td className="">
                                            {invoice.invoice_date
                                                ? formatDateFromDb(
                                                      invoice.invoice_date
                                                  )
                                                : "None"}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(
                                                invoice.start_date
                                            )}
                                            {" - "}
                                            {invoice.end_date
                                                ? formatDateFromDb(
                                                      invoice.end_date
                                                  )
                                                : "Today"}
                                        </td>
                                        <td className="">
                                            {invoice.status === "open"
                                                ? "Press to view"
                                                : formatMoney(invoice.total)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={`/invoices?offset=${calculateOffset(
                                    searchParams,
                                    "back"
                                )}`}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={`/invoices?offset=${calculateOffset(
                                    searchParams,
                                    "forward"
                                )}`}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center text-sm text-gray-500">
                    No past invoices.
                </div>
            )}
        </div>
    );
}

export default InvoicesTable;
