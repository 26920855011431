/** @format */
import Cookies from "js-cookie";

export const fetchDashboardData = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/statistics`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",

                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const updateBoxPrices = async (
    small_box_price: string,
    medium_box_price: string,
    xl_box_price: string
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/prices`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",

                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify({
                    small_box_price,
                    medium_box_price,
                    xl_box_price,
                }),
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};
