/** @format */

import Client from "../../../../../models/clients";
import { formatMoney } from "../../../../../utils/price/formatMoney";

interface AccountDetailsProps {
    client: Client | null;
}

export default function AccountDetails({ client }: AccountDetailsProps) {
    const clientInfo = [
        {
            label: "Full name",
            value: `${client?.first_name} ${client?.last_name}`,
        },
        { label: "Phone number", value: client?.phone_number },
        { label: "Email", value: client?.email },
        {
            label: "Amazon store name",
            value: client?.amazon_store_name || "Not provided",
        },
        {
            label: "Inventory Labs Credentials",
            value: client?.inventory_labs_email || "Not provided",
            value2: client?.inventory_labs_password,
        },
        {
            label: "Boxem Credentials",
            value: client?.boxem_email || "Not provided",
            value2: client?.boxem_password,
        },
        {
            label: "Tax rate",
            value: `${client?.tax_rate}%` || "Not provided",
        },
        {
            label: "Amazon fulfilled unit price",
            value: `${
                client?.amazon_unit_price
                    ? formatMoney(client?.amazon_unit_price)
                    : "None"
            }`,
        },
        {
            label: "Merchant fulfilled unit price",
            value: `${
                client?.merchant_unit_price
                    ? formatMoney(client?.merchant_unit_price)
                    : "None"
            }`,
        },
    ];

    return (
        <div className="pt-10">
            <h3 className="font-bold text-xl leading-7 text-gray-900">
                Client Information
            </h3>
            <div className="mt-6">
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                    {clientInfo.map((info) => (
                        <div
                            key={info.label}
                            className="border-t border-gray-100 backdrop: py-6 sm:col-span-1 sm:px-0"
                        >
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                {info.label}
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {info.value}
                            </dd>
                            {info.value2 ? (
                                <dd className="mt-1 text-sm leading-6 text-gray-700">
                                    {info.value2}
                                </dd>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
}
