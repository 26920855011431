import Cookies from "js-cookie";


export const fetchLabels = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/labels",
            {
                method: "GET",
                headers: { 
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data
    } catch(error) {
        return { data: {success: "failed", message: String(error)}}
    }
}

export const uploadLabel = async (
    selectedFile: File | null,
) => {
    try {
        if (!selectedFile) {
            return {success: false, message: "No file selected"}
        }
        const formData = new FormData();
        formData.append("label", selectedFile);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/labels",
            {
                method: "POST",
                headers: { 
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: formData
            }
        )
        const data = await response.json()
        return data
    } catch(error) {
        return { data: {success: "failed", message: String(error)}}
    }
}

export const deleteLabel = async (labelId: string | undefined) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/labels/" + labelId,
            {
                method: "DELETE",
                headers: { 
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data
    } catch(error) {
        return { data: {success: "failed", message: String(error)}}
    }
}