/** @format */

import { useCallback, useEffect, useState } from "react";
import { OrderTracking } from "../models/order";

export function useTrackingNumbersModal() {
    const [openTrackingNumbersModal, setOpenTrackingNumbersModal] =
        useState(false);

    const [selectedTrackingNumbers, setSelectedTrackingNumbers] = useState<
        OrderTracking[] | null
    >(null);

    useEffect(() => {
        if (selectedTrackingNumbers) {
            setOpenTrackingNumbersModal(true);
        }
    }, [selectedTrackingNumbers]);

    useEffect(() => {
        if (!openTrackingNumbersModal) {
            setTimeout(() => {
                setSelectedTrackingNumbers(null);
            }, 250);
        }
    }, [openTrackingNumbersModal]);

    const handleViewTrackings = useCallback((trackings: OrderTracking[]) => {
        setSelectedTrackingNumbers(trackings);
    }, []);

    return {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    };
}
