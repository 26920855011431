/** @format */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    InboundPageType,
    InventoryPageType,
    ShipmentPageSelector,
} from "../models/pageSelectors";

/** @format */

export type ValidPageTypes =
    | InboundPageType
    | InventoryPageType
    | ShipmentPageSelector;

export const usePage = (validPageTypes: ValidPageTypes[], basePath: string) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Get query parameters from the URL
    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("page") as ValidPageTypes;
    };

    // Set the initial page selected
    const [pageSelected, setPageSelected] = useState<ValidPageTypes>(
        getQueryParams() || validPageTypes[0]
    );

    useEffect(() => {
        const page = getQueryParams();
        if (page && validPageTypes.includes(page)) {
            setPageSelected(page);
        } else {
            setPageSelected(validPageTypes[0]);
            navigate(`${basePath}?page=${validPageTypes[0]}`, {
                replace: true,
            });
        }
    }, [location.search, navigate, validPageTypes, basePath]);

    // Change the page and update the URL
    const handlePageChange = (newPage: ValidPageTypes) => {
        if (validPageTypes.includes(newPage)) {
            setPageSelected(newPage);
            navigate(`${basePath}?page=${newPage}`);
        }
    };

    return { pageSelected, handlePageChange };
};
