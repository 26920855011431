import DashboardFeatures from "../../components/website/dashboardFeatures";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import Services from "../../components/website/services";

export default function HomePage() {
    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div className="scroll-smooth">
            <Header />
            <div className="absolute bottom-36" id="learn"></div>
            <div>
                <div className="relative isolate px-6 lg:px-8 h-screen flex items-center">
                    <div className="mx-auto max-w-2xl">
                        <div className="text-center">
                            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Focus on your business while we handle the rest.
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Urva Freight offers seamless order fulfillment
                                and ecommerce solutions, so you can grow without
                                the hassle.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <button
                                    onClick={() => scrollToSection("learn")}
                                    className="rounded-md bg-accent px-3.5
                                    py-2.5 text-sm font-semibold text-white
                                    shadow-sm hover:bg-accenthighlight"
                                >
                                    Learn more
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn"></div>
            <Services />
            <div className="flex flex-col items-center mx-auto px-6 lg:px-8 space-y-10">
                <div className="flex-col flex pt-36">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                        Dashboard Management
                    </h2>
                    <p className="max-w-3xl mt-6 text-lg leading-8 text-gray-600 text-center">
                        Welcome to your all-in-one dashboard, designed to
                        streamline your business operations. From effortless
                        order tracking and streamlined shipment management to
                        automated invoicing, our dashboard ensures you have
                        complete control and real-time insights into every
                        aspect of your workflow.
                    </p>
                </div>
                <div className="shadow-md rounded-xl border border-gray-200 overflow-hidden max-w-6xl">
                    <img src="/img-dashboard.png" alt="Dashboard" />
                </div>
            </div>
            <DashboardFeatures />
            <Footer />
        </div>
    );
}
