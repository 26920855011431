import ClientSideBar from "../../../components/client/sideBar";
import AccountSettings from "./accountSettings";

export default function SettingsPage() {
    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <AccountSettings />
        </div>
    );
}
