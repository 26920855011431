import Cookies from "js-cookie";
import { NavigateFunction, useNavigate } from "react-router-dom";


const STORAGE_KEY = 'auth_data';

export function handleLogout(navigate: NavigateFunction) {
    Cookies.remove("jwt")
    localStorage.removeItem(STORAGE_KEY);
    navigate("/login")
}