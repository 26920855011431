// authUtils.ts

const STORAGE_KEY = 'auth_data';
const AUTH_EXPIRY_DAYS = 7;

export interface AuthData {
    isAuthenticated: boolean;
    userRole: string;
    timestamp: number;
}

export const setAuthData = (data: AuthData) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const getAuthData = (): AuthData | null => {
    const data = localStorage.getItem(STORAGE_KEY);
    return data ? JSON.parse(data) : null;
};

export const isAuthDataValid = (data: AuthData): boolean => {
    const now = new Date().getTime();
    const expiryTime = data.timestamp + (AUTH_EXPIRY_DAYS * 24 * 60 * 60 * 1000);
    return now < expiryTime;
};

export const clearAuthData = () => {
    localStorage.removeItem(STORAGE_KEY);
};