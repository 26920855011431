import { GrHostMaintenance } from "react-icons/gr";
import { GrSecure } from "react-icons/gr";
import { GrStatusGood } from "react-icons/gr";

const features = [
    {
        name: "Listing Maintenance",
        description:
            "Our team keeps your product listings accurate and up-to-date across platforms, ensuring all product details are correct.",
        icon: GrHostMaintenance, // Replace with appropriate icon
    },
    {
        name: "Protection Against Unauthorized Sellers",
        description:
            "We protect your brand by monitoring listings to prevent unauthorized sellers, ensuring only quality-controlled products reach your customers.",
        icon: GrSecure, // Replace with appropriate icon
    },
    {
        name: "Quality Control and Timely Shipping",
        description:
            "We enforce strict quality control and timely shipping to ensure every customer interaction enhances your reputation and builds loyalty.",

        icon: GrStatusGood, // Replace with appropriate icon
    },
];

export default function ServicesEcommerce() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-28">
                <div className="flex flex-col items-center">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                            What we offer
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600 text-center">
                            Urva Freight provides comprehensive ecommerce
                            solutions, from efficient order fulfillment to
                            enhancing your online presence. We streamline your
                            operations so you can focus on growth.
                        </p>
                    </div>
                </div>

                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-accent">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-white"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                    <p className="flex-auto">
                                        {feature.description}
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
