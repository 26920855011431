/** @format */

import { useEffect, useState } from "react";
import { InputField } from "../../../../components/inputField";
import { updateBoxPrices } from "../../dashboard/api/dashboardApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { formatMoney } from "../../../../utils/price/formatMoney";
import LoadingWheel from "../../../../components/loadingWheel";
import { BoxPrices } from "../../../../models/adminDashboard";

function ViewBoxPrices({ boxPrices }: { boxPrices: BoxPrices | null }) {
    const [smallBoxPrice, setSmallBoxPrice] = useState("");
    const [mediumBoxPrice, setMediumBoxPrice] = useState("");
    const [largeBoxPrice, setLargeBoxPrice] = useState("");

    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const handleBoxPrices = async () => {
        setLoading(true);
        const data = await updateBoxPrices(
            smallBoxPrice,
            mediumBoxPrice,
            largeBoxPrice
        );
        if (data.status === "success") {
            showNotification("Updated box prices", "", "success");
        } else {
            showNotification("Failed to update prices", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        setSmallBoxPrice(formatMoney(boxPrices?.small_box) || "");
        setMediumBoxPrice(formatMoney(boxPrices?.medium_box) || "");
        setLargeBoxPrice(formatMoney(boxPrices?.extra_large_box) || "");
    }, [boxPrices]);

    return (
        <div className="bg-white border border-gray-100 sm:rounded-lg w-96">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Update box prices
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                        You can only update when all open invoices are closed.
                    </p>
                </div>
                <div className="space-y-4 pt-3">
                    <InputField
                        label="Small box price"
                        value={smallBoxPrice}
                        onChange={(e) => setSmallBoxPrice(e.target.value)}
                        placeholder=""
                    />
                    <InputField
                        label="Medium box price"
                        value={mediumBoxPrice}
                        onChange={(e) => setMediumBoxPrice(e.target.value)}
                        placeholder=""
                    />
                    <InputField
                        label="Large box price"
                        value={largeBoxPrice}
                        onChange={(e) => setLargeBoxPrice(e.target.value)}
                        placeholder=""
                    />
                    <button
                        onClick={() => handleBoxPrices()}
                        className="mt-3 w-full items-center justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                        {loading ? (
                            <LoadingWheel color="white" size="small" />
                        ) : (
                            <>Save</>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ViewBoxPrices;
