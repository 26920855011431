/** @format */

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../../components/loadingWheel";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { Order } from "../../../../../models/order";
import { FileUpload } from "../../../../../utils/files/fileUpload";
import { deleteLabel, fetchLabels, uploadLabel } from "../../api/labelApi";

import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import InventoryTable from "../inventoryTable";
import { Label } from "../../../../../models/label";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function LabelModal({
    selectedViewingLabel,
    openLabelModal,
    setOpenLabelModal,
    selectedPages,
    updateSelectedPages,
    handleLabelRefetch,
}: {
    selectedViewingLabel: Label | null;
    openLabelModal: boolean;
    setOpenLabelModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPages: number[];
    updateSelectedPages: (pages: number[]) => void;
    handleLabelRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [numPages, setNumPages] = useState<number | null>(null);

    const [deleteLoading, setDeleteLoading] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const togglePageSelection = (pageNumber: number) => {
        updateSelectedPages(
            selectedPages.includes(pageNumber)
                ? selectedPages.filter((page) => page !== pageNumber)
                : [...selectedPages, pageNumber]
        );
    };

    const handleSelectAll = () => {
        if (numPages && selectedViewingLabel?.used_pages) {
            const usedPagesSet = new Set(selectedViewingLabel.used_pages);
            const allAvailablePages = Array.from(
                { length: numPages },
                (_, i) => i + 1
            ).filter((pageNum) => !usedPagesSet.has(pageNum));

            updateSelectedPages(allAvailablePages);
        }
    };

    const handleDeselectAll = () => {
        updateSelectedPages([]); // Clear all selected pages
    };

    const handleDelete = async () => {
        setDeleteLoading(true);
        const data = await deleteLabel(selectedViewingLabel?.label_id);
        if (data.status === "success") {
            setOpenLabelModal(false);
            handleLabelRefetch();
        } else {
            showNotification("Failed to delete label", data.message, "error");
        }
        setDeleteLoading(false);
    };

    return (
        <div>
            <Dialog
                open={openLabelModal}
                onClose={() => setOpenLabelModal(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-4xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() => setOpenLabelModal(false)}
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                Request shipment
                                <span className="text-sm text-gray-800 font-normal">
                                    {" "}
                                    -{" "}
                                    {selectedPages.length > 0
                                        ? selectedPages.length
                                        : 0}{" "}
                                    selected pages
                                </span>
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex flex-row space-x-4 pt-2">
                                <button
                                    onClick={() => handleSelectAll()}
                                    className="text-white bg-accent hover:bg-accenthighlight rounded-md text-sm font-medium px-3 py-1"
                                >
                                    Select all
                                </button>
                                <button
                                    onClick={() => handleDeselectAll()}
                                    className="text-white bg-accent hover:bg-accenthighlight rounded-md text-sm font-medium px-3 py-1"
                                >
                                    Deselect all
                                </button>
                            </div>
                            <div className="flex-1 overflow-y-auto mt-3 mb-4">
                                <Document
                                    onLoadSuccess={({ numPages }) =>
                                        setNumPages(numPages)
                                    }
                                    file={selectedViewingLabel?.public_url}
                                >
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-items-center">
                                        {Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <div
                                                    key={`page_${index + 1}`}
                                                    className="mb-4"
                                                >
                                                    <div
                                                        onClick={
                                                            !selectedViewingLabel?.used_pages.includes(
                                                                index + 1
                                                            )
                                                                ? () =>
                                                                      togglePageSelection(
                                                                          index +
                                                                              1
                                                                      )
                                                                : () => {}
                                                        }
                                                        className={`${
                                                            selectedPages.includes(
                                                                index + 1
                                                            )
                                                                ? "border-4 border-accent"
                                                                : "border-4 border-gray-300"
                                                        } rounded-md shadow-sm overflow-hidden inline-block relative transition-all`}
                                                    >
                                                        <Page
                                                            pageNumber={
                                                                index + 1
                                                            }
                                                            width={300}
                                                            renderTextLayer={
                                                                false
                                                            }
                                                            renderAnnotationLayer={
                                                                false
                                                            }
                                                        />
                                                        {selectedViewingLabel?.used_pages.includes(
                                                            index + 1
                                                        ) && (
                                                            <div className="absolute inset-0 bg-gray-300 bg-opacity-80 pointer-events-none"></div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </Document>
                            </div>

                            {/* Fixed buttons at the bottom */}
                            <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                                <button
                                    onClick={() => setOpenLabelModal(false)}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                                >
                                    Confirm pages
                                </button>
                                <div className="space-x-2 items-center flex flex-row">
                                    <button
                                        type="button"
                                        onClick={() => handleDelete()}
                                        disabled={deleteLoading}
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                    >
                                        {deleteLoading ? (
                                            <span className="pr-2 items-center inline-flex justify-center">
                                                <LoadingWheel
                                                    size="small"
                                                    color="white"
                                                />
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setOpenLabelModal(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
