/** @format */

import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface MultiTrackingInputProps {
    trackingNumbers: string[];
    setTrackingNumbers: React.Dispatch<React.SetStateAction<string[]>>;
}

const MultiTrackingInput: React.FC<MultiTrackingInputProps> = ({
    trackingNumbers,
    setTrackingNumbers,
}) => {
    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();
            setTrackingNumbers((prev) => [...prev, currentInput.trim()]);
            setCurrentInput("");
        }
    };

    const removeTrackingNumber = (index: number) => {
        setTrackingNumbers((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <div className="space-y-0.5">
            <div className="flex flex-row justify-between">
                <span className="font-medium text-sm">Tracking</span>
                <span className="text-sm text-gray-500">Optional</span>
            </div>
            <input
                type="text"
                id="tracking"
                value={currentInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                // className="block w-full rounded-md border-gray-300 shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                placeholder="Press enter to add"
            />
            <div className="pt-0.5"></div>
            {trackingNumbers && trackingNumbers.length > 0 && (
                <div className="mt-4 flex flex-wrap gap-2">
                    {trackingNumbers.map((number, index) => (
                        <div
                            key={index}
                            className="flex items-center bg-gray-100 p-1.5 rounded-md"
                        >
                            <span className="text-xs mr-2">{number}</span>
                            <button
                                onClick={() => removeTrackingNumber(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                <XMarkIcon className="w-4 h-4" />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiTrackingInput;
