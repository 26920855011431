

export function calculateOffset(searchParams: URLSearchParams, direction: string) {
    const offset = searchParams.get("offset");
    if (offset) {
        if (direction === "back") {
            return Number(offset) - 1;
        }
        return Number(offset) + 1;
    } else {
        return 1;
    }
}

export function getOffset(searchParams: URLSearchParams) {
    const offset = searchParams.get("offset");
    if (offset) {
        return Number(offset);
    } else {
        return 0;
    }
}