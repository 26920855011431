/** @format */

import { ChangeEvent, FC } from "react";

interface InputFieldProps {
    label: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    optional?: boolean;
}

export const InputField: FC<InputFieldProps> = ({
    label,
    value,
    onChange,
    placeholder,
    optional,
}) => (
    <div className="text-left w-full space-y-0.5">
        <div className="flex flex-row items-center justify-between">
            <span className="font-medium text-sm">{label}</span>
            {optional ? (
                <span className="text-sm text-gray-500">Optional</span>
            ) : (
                <></>
            )}
        </div>
        <input
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
        />
    </div>
);

export const InputNumberField: FC<InputFieldProps> = ({
    label,
    value,
    onChange,
    placeholder,
    optional,
}) => (
    <div className="text-left w-full space-y-0.5">
        <div className="flex flex-row items-center justify-between">
            <span className="font-medium text-sm">{label}</span>
            {optional ? (
                <span className="text-sm text-gray-500">Optional</span>
            ) : (
                <></>
            )}
        </div>
        <input
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type="number"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
        />
    </div>
);
