/** @format */

import { useEffect, useRef, useState } from "react";
import SideBar from "../../../components/client/sideBar";
import { MdOutlineFileUpload } from "react-icons/md";
import { AddBulkOrderModal, AddOrderModal } from "./addOrderModal";
import InboundTable from "./inboundTable";
import Header from "../../../components/header";
import Results from "../../../models/results";
import { fetchInboundOrders } from "./api/inboundApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingWheel from "../../../components/loadingWheel";
import { useNotification } from "../../../utils/notification/notificationContext";
import { Order } from "../../../models/order";
import InboundPageSelector from "./inboundPageSelector";
import { InboundPageType } from "../../../models/pageSelectors";
import { fetchTrackings } from "../inventory/api/trackingsApi";
import { TrackingArrived } from "../../../components/trackingArrived";
import TrackingsArrivedTable from "../inventory/tracking-data/trackingsTable";
import { useSort } from "../../../hooks/useSort";
import { usePage, ValidPageTypes } from "../../../hooks/usePage";

function InboundPage() {
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const inboundPageTypes: InboundPageType[] = ["units", "trackings"];
    const { pageSelected, handlePageChange } = usePage(
        inboundPageTypes,
        "/inbound"
    );

    const [openAddOrderModal, setOpenAddOrderModal] = useState(false);
    const [openAddBulkOrderModal, setOpenAddBulkOrderModal] = useState(false);

    const [dataLoading, setDataLoading] = useState(false);
    const [inboundOrders, setInboundOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [trackingsLoading, setTrackingsLoading] = useState(false);
    const [trackings, setTrackings] = useState<TrackingArrived[]>([]);
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        setSortLoading(true);
        const data = await fetchInboundOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setInboundOrders(data.data.inbound);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setDataLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setDataLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        fetchData();
    }, [sortConfig]);

    const hasFetchedTrackings = useRef(false); // Create a ref to track if data has been fetched

    useEffect(() => {
        if (pageSelected === "trackings" && !hasFetchedTrackings.current) {
            const fetchData = async () => {
                setTrackingsLoading(true);
                const data = await fetchTrackings(searchParams, "inbound");
                if (data.status === "success") {
                    setTrackings(data.data.trackings);
                    setTrackingsResults(data.data.results);
                    hasFetchedTrackings.current = true; // Set ref to true after fetching
                } else {
                    showNotification(
                        "Failed fetching data",
                        data.message,
                        "error"
                    );
                }
                setTrackingsLoading(false);
            };
            fetchData();
        }
    }, [pageSelected]);

    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <span className="text-2xl font-bold">
                            Inbound Shipments
                        </span>
                        <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                            <button
                                onClick={() => setOpenAddOrderModal(true)}
                                className="bg-accent text-white font-medium px-5 text-sm rounded-md h-8 hover:bg-accenthighlight"
                            >
                                New Inbound Shipment
                            </button>
                            <button
                                onClick={() => setOpenAddBulkOrderModal(true)}
                                className="flex flex-row items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                            >
                                <MdOutlineFileUpload />
                                <span className="pl-2 text-sm">Upload CSV</span>
                            </button>
                        </div>
                    </div>

                    <InboundPageSelector
                        pageSelected={pageSelected}
                        handlePageChange={handlePageChange}
                    />

                    <div className="pt-10"></div>
                    {pageSelected === "units" ? (
                        <>
                            {!dataLoading ? (
                                <InboundTable
                                    inboundOrders={inboundOrders}
                                    results={results}
                                    handleRefetch={handleRefetch}
                                    setOpenAddOrderModal={setOpenAddOrderModal}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    sortLoading={sortLoading}
                                />
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </>
                    ) : (
                        <TrackingsArrivedTable
                            trackings={trackings}
                            results={trackingsResults}
                            loading={trackingsLoading}
                            handleRefetch={handleRefetch}
                            type="inbound"
                        />
                    )}
                </div>
            </div>
            <AddOrderModal
                openAddOrderModal={openAddOrderModal}
                setOpenAddOrderModal={setOpenAddOrderModal}
                handleRefetch={handleRefetch}
            />

            <AddBulkOrderModal
                openAddBulkOrderModal={openAddBulkOrderModal}
                setOpenAddBulkOrderModal={setOpenAddBulkOrderModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default InboundPage;
