/** @format */

import DashboardCardsModel from "../../../models/dashboardCards";

export default function DashboardCards({
    dashboardCardsData,
}: {
    dashboardCardsData: DashboardCardsModel | null;
}) {
    const data = [
        { label: "Inbound units", value: dashboardCardsData?.inbound },
        {
            label: "Received units",
            value: dashboardCardsData?.inventory,
        },
        { label: "Outbound units", value: dashboardCardsData?.outbound },
    ];

    return (
        <div className="pb-10">
            <div className="grid grid-cols-1 gap-px bg-gray-300 sm:grid-cols-3 mt-10">
                {data.map((item) => (
                    <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                        <p className="text-sm font-medium leading-6 text-gray-600">
                            {item.label}
                        </p>
                        <p className="mt-2 flex items-baseline gap-x-2">
                            <span className="text-4xl font-semibold tracking-tight text-black">
                                {item.value}
                            </span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
