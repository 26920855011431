/** @format */

import Results from "../../models/results";

export const PaginationResults: React.FC<{
    results: Results;
}> = ({ results }) => (
    <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{results.showing_from}</span> to{" "}
        <span className="font-medium">{results.showing_to}</span> of{" "}
        <span className="font-medium">{results.total_results}</span> results
    </p>
);
