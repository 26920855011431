/** @format */

import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
    useEffect,
} from "react";

interface NotificationContextType {
    showNotification: (
        title: string,
        subtitle?: string,
        type?: "success" | "error"
    ) => void;
    hideNotification: () => void;
    notification: {
        title: string;
        subtitle?: string;
        type?: "success" | "error";
    } | null;
    isVisible: boolean;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
    undefined
);

export function NotificationProvider({ children }: { children: ReactNode }) {
    const [notification, setNotification] = useState<{
        title: string;
        subtitle?: string;
        type?: "success" | "error";
    } | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const showNotification = (
        title: string,
        subtitle?: string,
        type?: "success" | "error"
    ) => {
        setNotification({ title, subtitle, type });
        setIsVisible(true);
    };

    const hideNotification = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                hideNotification();
            }, 3000); // 2 seconds

            return () => clearTimeout(timer);
        }
    }, [isVisible]);

    return (
        <NotificationContext.Provider
            value={{
                showNotification,
                hideNotification,
                notification,
                isVisible,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export function useNotification() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error(
            "useNotification must be used within a NotificationProvider"
        );
    }
    return context;
}
