/** @format */

import { useEffect, useState } from "react";
import { PiShippingContainer } from "react-icons/pi";
import { TbBus } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineCreditCard } from "react-icons/hi2";

import Settings from "../settings";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const navigation = [
    { name: "Dashboard", href: "/", icon: LuLayoutDashboard },
    { name: "Inbound", href: "/inbound", icon: TbBus },
    { name: "Inventory", href: "/inventory", icon: MdOutlineInventory2 },
    { name: "Outbound", href: "/outbound", icon: PiShippingContainer },
    { name: "Billing", href: "/billing", icon: HiOutlineCreditCard },
];

export default function ClientSideBar() {
    const [currentPath, setCurrentPath] = useState("");
    const [isMinimized, setIsMinimized] = useState(() => {
        // Initialize state from localStorage, default to false if not set
        const saved = localStorage.getItem("sidebarMinimized");
        return saved !== null ? JSON.parse(saved) : false;
    });

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        setCurrentPath(normalizedPath);
    }, []);

    useEffect(() => {
        // Save state to localStorage whenever it changes
        localStorage.setItem("sidebarMinimized", JSON.stringify(isMinimized));
    }, [isMinimized]);

    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    return (
        <div className="hidden lg:block">
            <div className="flex flex-row">
                {/* Sidebar */}
                <div
                    className={`fixed top-0 h-screen pt-6 flex flex-col justify-between bg-white border-r border-gray-300 transition-all duration-300 ease-in-out ${
                        isMinimized ? "w-16" : "w-64"
                    }`}
                >
                    <div>
                        <div
                            className={`flex ${
                                isMinimized
                                    ? "justify-center"
                                    : "justify-start pl-4"
                            }`}
                        >
                            <img
                                alt="logo"
                                src="/branding/logo-white.jpeg"
                                className={`${
                                    isMinimized ? "w-10" : "w-20"
                                } h-auto transition-all duration-300`}
                            />
                        </div>
                        <div className="pt-10"></div>
                        {navigation.map((item) => (
                            <div
                                className={`pt-1 ${
                                    isMinimized ? "px-2" : "pl-4 pr-2"
                                }`}
                                key={item.name}
                            >
                                <a
                                    href={item.href}
                                    className={`${
                                        currentPath === item.href
                                            ? "bg-gray-100 text-accent"
                                            : "hover:bg-gray-100 hover:text-accent group text-gray-700"
                                    } flex flex-row items-center ${
                                        isMinimized
                                            ? "justify-center"
                                            : "space-x-3"
                                    } rounded-md p-2`}
                                    title={item.name}
                                >
                                    <item.icon
                                        className={`h-6 w-6 shrink-0 group-hover:text-accent ${
                                            currentPath === item.href
                                                ? "text-accent"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    {!isMinimized && (
                                        <span className="text-sm font-semibold">
                                            {item.name}
                                        </span>
                                    )}
                                </a>
                            </div>
                        ))}
                    </div>

                    {/* Toggle button */}
                    <div className="flex flex-col justify-center">
                        <button
                            onClick={toggleSidebar}
                            className="p-2 mb-4 mx-auto bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        >
                            {isMinimized ? (
                                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                            ) : (
                                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                            )}
                        </button>
                        <div
                            className={`pb-4 ${
                                isMinimized
                                    ? "justify-center flex"
                                    : "pl-4 pr-2"
                            } z-50`}
                        >
                            <Settings isMinimized={isMinimized} />
                        </div>
                    </div>
                </div>

                {/* Add margin to content to account for the fixed sidebar */}
                <div
                    className={`transition-all duration-300 ease-in-out ${
                        isMinimized ? "ml-16" : "ml-64"
                    }`}
                >
                    {/* <div className="h-full absolute w-px bg-gray-300 z-10"></div> */}
                </div>
            </div>
        </div>
    );
}
