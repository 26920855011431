/** @format */

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { StatusTag } from "../../../../../../components/statusTags";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import TrackingNumbersModal from "../../../../../../components/trackingNumbersModal";
import CheckInDatesModal from "../../../../../../components/checkInDatesModal";
import { CheckInDate } from "../../../../../../models/checkInDate";
import { useCheckInDatesModal } from "../../../../../../hooks/useCheckInDates";
import { useTrackingNumbersModal } from "../../../../../../hooks/useTrackingNumbers";
import { Order } from "../../../../../../models/order";
import { PaginationLink } from "../../../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../../../utils/pagination/paginationResults";

interface ClientInboundTableProps {
    inboundOrders: Order[];
    clientInboundResults?: Results;
    selectedInboundOrders?: Order[];
    setSelectedInboundOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
}

function ClientInboundTable({
    inboundOrders,
    clientInboundResults,
    selectedInboundOrders,
    setSelectedInboundOrders,
}: ClientInboundTableProps) {
    const [searchParams] = useSearchParams();

    const { user_id } = useParams();

    const [loading] = useState(false);

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedInboundOrders && setSelectedInboundOrders) {
            if (selectedInboundOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedInboundOrders(
                    selectedInboundOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedInboundOrders([...selectedInboundOrders, order]);
            }
        }
    };

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    return (
        <div className="flex flex-col">
            {!loading && inboundOrders && inboundOrders.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {selectedInboundOrders ? (
                                        <th className="py-2 min-w-10"></th>
                                    ) : (
                                        <></>
                                    )}
                                    <th className="py-2 sm:min-w-32 min-w-32">
                                        Status
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Date
                                    </th>
                                    <th className="py-2 min-w-32">Retailer</th>
                                    <th className="py-2 min-w-32">Order #</th>
                                    <th className="py-2 sm:min-w-48 min-w-48">
                                        Item Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Brand SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Size
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Quantity
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Color
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        ASIN
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Amazon SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Unit price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        List price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Trackings
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {inboundOrders.map((order) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {selectedInboundOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={selectedInboundOrders.some(
                                                        (o) =>
                                                            o.order_id ===
                                                            order.order_id
                                                    )}
                                                />
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        <td className="py-2.5">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td className="">{order.quantity}</td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td className="">
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onClick={() =>
                                                handleViewTrackings(
                                                    order.trackings
                                                )
                                            }
                                            className="text-accent"
                                        >
                                            View
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {clientInboundResults ? (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <PaginationResults
                                    results={clientInboundResults}
                                />
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={`/admin/clients/${user_id}?page=shipments&status=inbound&offset=${calculateOffset(
                                        searchParams,
                                        "back"
                                    )}`}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={`/admin/clients/${user_id}?page=shipments&status=inbound&offset=${calculateOffset(
                                        searchParams,
                                        "forward"
                                    )}`}
                                    disabled={
                                        clientInboundResults.showing_to >=
                                        clientInboundResults.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no inbound orders
                    </span>
                </div>
            )}

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
            />
        </div>
    );
}

export default ClientInboundTable;
