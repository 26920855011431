/** @format */

import React, { useRef, useEffect, useState } from "react";
import { updateTax } from "./api/dashboardApi";
import LoadingWheel from "../../../components/loadingWheel";

interface TaxPopupProps {
    isOpen: boolean;
    onClose: () => void;
    setTax: React.Dispatch<React.SetStateAction<string>>;
    tax: string;
    taxButtonRef: React.RefObject<HTMLButtonElement>; // Add this prop
    handleUpdateTax: () => void;
    taxLoading: boolean;
    type: "set" | "edit";
}

export const TaxPopup: React.FC<TaxPopupProps> = ({
    isOpen,
    onClose,
    setTax,
    tax,
    taxButtonRef, // Destructure this prop
    handleUpdateTax,
    taxLoading,
    type,
}) => {
    const taxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                taxRef.current &&
                !taxRef.current.contains(event.target as Node) &&
                taxButtonRef.current &&
                !taxButtonRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose, taxButtonRef]);

    return (
        <div
            ref={taxRef}
            className={`${
                type === "set" ? "left-0 top-12" : "right-0 top-8"
            } absolute w-64 h-32 bg-white z-50 rounded-md border border-gray-200 shadow-sm px-2 flex flex-col justify-between py-2 transition-opacity duration-300 ease-in-out ${
                isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
        >
            <div className="font-medium text-sm">Tax</div>
            <input
                className="block w-full rounded-md border-0 py-0.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent text-xs leading-6"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
            />
            <button
                onClick={handleUpdateTax}
                className="w-full bg-accent text-xs text-white h-7 rounded-md hover:bg-accenthighlight"
            >
                {!taxLoading ? (
                    "Apply"
                ) : (
                    <LoadingWheel size="small" color="white" />
                )}
            </button>
        </div>
    );
};
