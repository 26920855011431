/** @format */

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../utils/notification/notificationContext";
import { TrackingArrived } from "../../../components/trackingArrived";
import Results from "../../../models/results";
import { fetchAllShipmentTrackings } from "./api/outboundApi";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import LoadingWheel from "../../../components/loadingWheel";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import LimitSelector from "../../../utils/pagination/limitSelector";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";

function OutboundTrackingsTable() {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();

    const [trackingsArrived, setTrackingsArrived] = useState<TrackingArrived[]>(
        []
    );
    const [trackingsArrivedResults, setTrackingsArrivedResults] =
        useState<Results>({
            showing_from: 0,
            showing_to: 0,
            total_results: 0,
            current_page: 0,
        });

    const [offset, setOffset] = useState(0);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchAllShipmentTrackings(searchParams);
            if (data.status === "success") {
                setTrackingsArrived(data.data.trackings);
                setTrackingsArrivedResults(data.data.results);
            } else {
                showNotification(
                    "Failed fetching orders",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        };
        fetchData();
    }, [offset, refetchData]);

    const tableHeaders = [
        {
            key: "date_shipped",
            label: "Date Shipped",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking",
            label: "Tracking number",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "carrier",
            label: "Detected Carrier",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking_url",
            label: "URL",
            className: "sm:min-w-32 min-w-28",
        },
    ];

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    useEffect(() => {
        if (selectedTrackingId) {
            setOpenDeleteModal(true);
        }
    }, [selectedTrackingId]);

    useEffect(() => {
        if (!openDeleteModal) {
            setSelectedTrackingId(null);
        }
    }, [openDeleteModal]);

    return (
        <div className="flex flex-col pt-3">
            {!loading && trackingsArrived && trackingsArrived.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            className={`py-2 ${header.className}`}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {trackingsArrived.map((tracking) => (
                                    <tr
                                        key={tracking.tracking}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        <td className="py-2.5">
                                            {formatDateFromDb(tracking.date)}
                                        </td>
                                        <td className="py-2.5">
                                            {tracking.tracking}
                                        </td>
                                        <td className="">{tracking.carrier}</td>
                                        {tracking.tracking_url !== "Unknown" ? (
                                            <td className="">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={tracking.tracking_url}
                                                    className="text-accent"
                                                >
                                                    View
                                                </a>
                                            </td>
                                        ) : (
                                            <td>Undetermined</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-2">
                                <PaginationResults
                                    results={trackingsArrivedResults}
                                />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/outbound?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/outbound?${params.toString()}`;
                                })()}
                                disabled={
                                    trackingsArrivedResults.showing_to >=
                                    trackingsArrivedResults.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no trackings added
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}

export default OutboundTrackingsTable;
