/** @format */

import { CheckIcon } from "@heroicons/react/20/solid";
import Header from "../../components/website/header";
import Footer from "../../components/website/footer";
import { useNotification } from "../../utils/notification/notificationContext";
import { useState } from "react";
import { sendContactRequest } from "./api/contactApi";
import LoadingWheel from "../../components/loadingWheel";

const features = [
    {
        name: "Wholesale Prep",
        description:
            "We'll receive, process, and ship out your Wholesale shipments, directly to Amazon's warehouses or to your customers.",
        description2: "Prices start at $0.50.",

        button: "quote",
    },
    {
        name: "Online Arbitrage Prep",
        description:
            "We'll receive, process, and reship your Online Arbitrage shipments, directly to Amazon's warehouses or to your customers.",
        description2: "Prices start at $1.00.",
        button: "quote",
    },

    {
        name: "Private Label Prep",
        description:
            "We'll receive, process, and ship out your Private Label shipments, directly to Amazon's warehouses or to your customers.",
        description2: "Prices start at $0.50.",
        button: "quote",
    },
    {
        name: "Other Order Fulfillment Services",
        description:
            "Do you operate an online store outside of Amazon? Let us take care of all your fulfillment needs from A to Z. For any order fulfillment services, we’re your go-to partner.",
        description2: "",
        button: "quote",
    },
    {
        name: "Return Management",
        description:
            "We handle receiving, processing, and storing your returns. Resellable items are sent back to your FBA inventory, while unsellable stock is consolidated and reshipped to you monthly.",
        description2: "",
        button: "none",
    },
];

export default function OrderFulfillmentPage() {
    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const handleContact = async () => {
        setLoading(true);
        const data = await sendContactRequest(
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber,
            message
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <div>
                <div className="mt-3 bg-white pt-24 sm:pt-32">
                    <div className="mx-auto max-w-8xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            <div className="lg:mr-10">
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Order Fulfillment Services
                                </p>
                                <img
                                    className="object-cover w-full h-96 mt-10 rounded-md border border-gray-200"
                                    src="/img-boxes.jpeg"
                                ></img>
                            </div>
                            <dl className="col-span-2 grid grid-cols-1 lg:gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-10">
                                {features.map((feature) => (
                                    <div
                                        key={feature.name}
                                        className="relative pl-9"
                                    >
                                        <dt className="font-semibold text-gray-900">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="absolute left-0 top-1 h-5 w-5 text-accent"
                                            />
                                            {feature.name}
                                        </dt>
                                        <dd className="mt-2">
                                            {feature.description}
                                        </dd>
                                        <dd
                                            className={`${
                                                feature.description2 === ""
                                                    ? "mt-0"
                                                    : "mt-2 font-semibold"
                                            }`}
                                        >
                                            {feature.description2}
                                        </dd>
                                        {feature.button === "pricing" ? (
                                            <div className="text-accent hover:text-accenthighlight inline-block rounded-md cursor-pointer mt-2 text-sm">
                                                <button
                                                    onClick={() =>
                                                        scrollToSection(
                                                            "pricing"
                                                        )
                                                    }
                                                >
                                                    See pricing
                                                </button>
                                            </div>
                                        ) : feature.button === "quote" ? (
                                            <div className="text-accent hover:text-accenthighlight inline-block rounded-md cursor-pointer mt-4 text-sm">
                                                <button
                                                    onClick={() =>
                                                        scrollToSection("quote")
                                                    }
                                                >
                                                    Request a quote
                                                </button>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div id="quote" className="pt-24 sm:pt-32 px-6 lg:px-8">
                        <div className="mx-auto max-w-xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-10">
                                Request a quote
                            </h2>
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        First name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            id="first-name"
                                            name="first-name"
                                            type="text"
                                            autoComplete="given-name"
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Last name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            id="last-name"
                                            name="last-name"
                                            type="text"
                                            autoComplete="family-name"
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="company-name"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Company Name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            id="company-name"
                                            name="company-name"
                                            type="company-name"
                                            value={companyName}
                                            onChange={(e) =>
                                                setCompanyName(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="phone-number"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Phone number
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            id="phone-number"
                                            name="phone-number"
                                            type="tel"
                                            autoComplete="tel"
                                            value={phoneNumber}
                                            onChange={(e) =>
                                                setPhoneNumber(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Message
                                    </label>
                                    <div className="mt-2.5">
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={4}
                                            value={message}
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 flex justify-end">
                                <button
                                    onClick={handleContact}
                                    className="rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                                >
                                    {!loading ? (
                                        <>Request quote</>
                                    ) : (
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
