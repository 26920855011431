/** @format */

export const PaginationLink: React.FC<{
    href: string;
    disabled: boolean;
    text: string;
    className?: string;
}> = ({ href, disabled, text, className = "" }) => (
    <a
        href={href}
        className={`${
            disabled
                ? "pointer-events-none text-gray-400 ring-gray-200"
                : "text-gray-900 ring-gray-300"
        } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ${className}`}
    >
        {text}
    </a>
);
