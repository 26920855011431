/** @format */

"use client";

import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
    { name: "Home", href: "/" },
    { name: "Order Fulfillment", href: "/order-fulfillment" },
    { name: "Ecommerce Management", href: "/ecommerce-management" },
    { name: "Contact", href: "/contact" },
];

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        // Set the current path when the component mounts
        setCurrentPath(window.location.pathname);
    }, []);

    return (
        <div className="w-full fixed top-0 z-40">
            <header className="bg-white">
                <nav
                    aria-label="Global"
                    className="flex items-center justify-between p-6 md:px-8"
                >
                    <div className="flex md:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">URVA Freight</span>
                            <img
                                alt=""
                                src="/branding/logo-white.jpeg"
                                className="h-8 w-auto"
                            />
                        </a>
                    </div>
                    <div className="flex md:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden md:flex md:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className={`text-sm font-semibold leading-6 ${
                                    currentPath === item.href
                                        ? "text-accent"
                                        : "text-gray-900 hover:text-accent"
                                }`}
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden md:flex md:flex-1 md:justify-end">
                        <a
                            href="#"
                            className="rounded-lg bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight"
                        >
                            Login
                        </a>
                    </div>
                </nav>
                <Dialog
                    open={mobileMenuOpen}
                    onClose={setMobileMenuOpen}
                    className="md:hidden"
                >
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">URVA Freight</span>
                                <img
                                    alt=""
                                    src="/branding/logo-white.jpeg"
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                                                currentPath === item.href
                                                    ? "text-accent"
                                                    : "text-gray-900 hover:text-accent"
                                            }`}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="#"
                                        className="-mx-3 block rounded-lg bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight leading-7"
                                    >
                                        Log in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>
        </div>
    );
}
