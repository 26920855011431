/** @format */
/** @format */
import { useState } from "react";
import LoadingWheel from "../../../components/loadingWheel";
import { signupRequest } from "../api/authenticationApi";
import { useNavigate } from "react-router-dom";

export default function SignUpPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");

    const [failed, setFailed] = useState(false);

    return (
        <div className="bg-gray-50 h-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 pb-12 pt-10 shadow sm:rounded-lg sm:px-12">
                        <div className="flex flex-row justify-between items-start">
                            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Create an account
                            </h2>
                            <img
                                alt="Your Company"
                                src="/branding/urva-white-name.jpeg"
                                className="h-auto w-20"
                            />
                        </div>
                        <div className="space-y-6 mt-6">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row w-full space-x-0 sm:space-x-4 space-y-6 sm:space-y-0">
                                <div className="w-full">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        First Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            disabled={loading}
                                            required
                                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Last Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            disabled={loading}
                                            required
                                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        required
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex flex-row justify-between">
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password
                                    </label>
                                </div>
                                <div className="mt-2 pb-2">
                                    <input
                                        disabled={loading}
                                        id="password"
                                        name="password"
                                        type="password"
                                        required
                                        autoComplete="password"
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {failed ? (
                                <span className="text-red-500 text-sm">
                                    Email or password is incorrect.
                                </span>
                            ) : (
                                <></>
                            )}

                            <div>
                                <button
                                    disabled={email.length < 1 || loading}
                                    onClick={() =>
                                        signupRequest(
                                            email,
                                            firstName,
                                            lastName,
                                            phone,
                                            password,
                                            "client",
                                            navigate,
                                            setLoading,
                                            setFailed
                                        )
                                    }
                                    type="submit"
                                    className={`${
                                        email.length >= 1 && !loading
                                            ? "bg-accent hover:bg-accenthighlight cursor-pointer transition"
                                            : email.length >= 1 && loading
                                            ? "bg-accent cursor-default transition"
                                            : "bg-accentdim cursor-default transition"
                                    } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                >
                                    {loading ? (
                                        <LoadingWheel color="white" />
                                    ) : (
                                        <span>Create account</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already have an account?{" "}
                        <a
                            href="/login"
                            className="font-semibold leading-6 text-accent hover:text-accenthighlight"
                        >
                            Login
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
