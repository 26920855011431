/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import ClientFilters from "./actions/clientFilters";
import ClientsTable from "./clientsTable";
import { fetchClients } from "./api/clientsApi";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Results from "../../../models/results";
import Client from "../../../models/clients";
import LoadingWheel from "../../../components/loadingWheel";
import HeaderSearchBar from "../../../components/searchBar";
import { PlClientsPageType } from "../../../models/pageSelectors";
import { useNotification } from "../../../utils/notification/notificationContext";
import ClientSettings from "./settings";

const selectorsData = [
    { label: "Clients", value: "clients" },
    { label: "Settings", value: "settings" },
];

export default function ClientsPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedClients, setSelectedClients] = useState<string[]>([]);
    const [allClientsSelected, setAllClientsSelected] = useState(false);

    const [clients, setClients] = useState<Client[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const data = await fetchClients(searchParams);
            if (data.status === "success") {
                setClients(data.data.clients);
                setResults(data.data.results);
            } else {
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (queryParams.get("page") as PlClientsPageType) || "clients";
    };

    const [pageSelected, setPageSelected] = useState<PlClientsPageType>(
        getQueryParams()
    );

    useEffect(() => {
        const page = getQueryParams();
        setPageSelected(page);
        // Navigate to the correct page if URL is invalid
        if (page && ["clients", "settings"].indexOf(page) === -1) {
            navigate(`/admin/clients?page=clients`, {
                replace: true,
            });
        }
    }, [location.search, navigate]);

    const handlePageChange = (newPage: PlClientsPageType) => {
        setPageSelected(newPage);
        navigate(`/admin/clients?page=${newPage}`);
    };

    return (
        <div className="flex flex-row">
            <AdminSideBar />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto">
                <Header />

                <div className="py-5">
                    <HeaderSearchBar
                        setClients={setClients}
                        setLoading={setLoading}
                        handleRefetch={handleRefetch}
                    />
                </div>
                <div className="overflow-x-auto">
                    <div className="flex flex-row justify-between items-center w-full">
                        <span className="text-2xl font-bold">
                            Third-Party Logistics
                        </span>
                    </div>
                    <div className="flex flex-col pt-4">
                        <div className="flex flex-row font-semibold pb-2 relative">
                            {selectorsData.map((item) => (
                                <button
                                    className={`relative ${
                                        pageSelected === item.value
                                            ? "text-accent after:bg-purple-500"
                                            : "text-gray-400 after:bg-transparent"
                                    } w-36 after:absolute after:left-0 after:bottom-[-12px] after:h-1 after:w-full`}
                                    onClick={() =>
                                        handlePageChange(
                                            item.value as PlClientsPageType
                                        )
                                    }
                                >
                                    {item.label}
                                </button>
                            ))}
                        </div>
                        <div className="w-full h-1 bg-gray-300"></div>
                    </div>
                    <div className="pt-4"></div>
                    {pageSelected === "clients" ? (
                        <>
                            {!loading ? (
                                <ClientsTable
                                    clients={clients}
                                    results={results}
                                    selectedClients={selectedClients}
                                    setSelectedClients={setSelectedClients}
                                    setAllClientsSelected={
                                        setAllClientsSelected
                                    }
                                />
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </>
                    ) : (
                        <ClientSettings />
                    )}
                </div>
            </div>
        </div>
    );
}
