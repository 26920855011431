/** @format */

import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import AdminMobileSideBar from "./admin/mobileSidebar";
import ClientMobileSideBar from "./client/mobileSidebar";

export default function Header() {
    const [adminMobileSidebar, setAdminMobileSidebar] = useState(false);
    const [clientMobileSidebar, setClientMobileSidebar] = useState(false);

    // Function to determine if URL contains "admin"
    const isAdmin = () => window.location.href.includes("admin");

    // Function to toggle the appropriate sidebar based on the URL
    const handleHamburgerClick = () => {
        if (isAdmin()) {
            setAdminMobileSidebar(!adminMobileSidebar);
        } else {
            setClientMobileSidebar(!clientMobileSidebar);
        }
    };

    return (
        <div>
            <AdminMobileSideBar
                hidden={!adminMobileSidebar}
                toggleSidebar={() => setAdminMobileSidebar(false)}
            />
            <ClientMobileSideBar
                hidden={!clientMobileSidebar}
                toggleSidebar={() => setClientMobileSidebar(false)}
            />
            <div className="lg:hidden">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between bg-white py-3 sm:py-5 top-0 items-center">
                        <RxHamburgerMenu
                            className="w-5 h-5 cursor-pointer"
                            onClick={handleHamburgerClick}
                        />
                        <div className="flex flex-row h-8 items-center">
                            <img
                                alt="logo"
                                src="/branding/logo-white.jpeg"
                                className="w-16 h-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
