import Cookies from "js-cookie";
import AccountData from "../../../../models/accountData";

export const fetchAccountDetails = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setAccountDetails: React.Dispatch<React.SetStateAction<AccountData | null>>,
    setFormValues: React.Dispatch<React.SetStateAction<AccountData | null>> // Adjust type for dictionary
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();

        if (data.status === "success") {
            setAccountDetails(data.data); // Assuming the entire data object is the dictionary
            setFormValues(data.data)
            setLoading(false);
        } else {
            setLoading(false);
            console.error('Failed to retrieve account details', data);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching account details', error);
    }
};


export const updateAccountDetails = async (
    section: string,
    accountDetails: AccountData | null,
) => {
    try {
        
        var jsonBody = {}
        
        if (section === "personal") {
            jsonBody = {
                section: section,
                first_name: accountDetails?.first_name,
                last_name: accountDetails?.last_name,
                phone_number: accountDetails?.phone_number,
                
            }

        } else if (section === "integrations") {
            jsonBody = {
                section: section,
                amazon_store_name: accountDetails?.amazon_store_name,
                inventory_labs_email: accountDetails?.inventory_labs_email,
                inventory_labs_password: accountDetails?.inventory_labs_password,
                boxem_email: accountDetails?.boxem_email,
                boxem_password: accountDetails?.boxem_password,
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json()
        return {success: data.status === "success", message: data.message}
        
    } catch (error) {
        return {success: false, message: String(error)}
    }
}
