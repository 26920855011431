/** @format */

import React, { useState, useEffect } from "react";
import { fetchAccountDetails, updateAccountDetails } from "./api/settingsApi";
import Header from "../../../components/header";
import LoadingWheel from "../../../components/loadingWheel";
import AccountData from "../../../models/accountData";
import { SuccessNotification } from "../../../components/statusNotifications";
import { useNotification } from "../../../utils/notification/notificationContext";

export default function AccountSettings() {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(true);
    const [accountDetails, setAccountDetails] = useState<AccountData | null>(
        null
    );
    const [sectionLoading, setSectionLoading] = useState("");
    const [updateAccountDetailsSuccess, setUpdateAccountDetailsSuccess] =
        useState(false);

    const [formValues, setFormValues] = useState<AccountData | null>(null);

    useEffect(() => {
        fetchAccountDetails(setLoading, setAccountDetails, setFormValues);
    }, []);

    const handleUpdateAccountDetails = async (section: string) => {
        if (formValues) {
            const updatedAccountDetails = {
                ...accountDetails,
                ...formValues,
            };

            setSectionLoading(section);
            const { success, message } = await updateAccountDetails(
                section,
                updatedAccountDetails
            );
            if (success) {
                setAccountDetails(updatedAccountDetails);
                showNotification("Updated account details", "", "success");
            } else {
                console.log(success);
                console.log(message);
                if (section === "personal") {
                    handleResetPersonalInfo();
                } else {
                    handleResetIntegrations();
                }
                showNotification("Error updating details", message, "error");
            }
            setSectionLoading("");
        }
    };

    if (loading) {
        return (
            <div className="flex flex-grow items-center h-screen justify-center pb-64">
                <LoadingWheel />
            </div>
        );
    }

    if (!accountDetails) {
        return <div>Account details not found</div>;
    }

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues!,
            [name]: value,
        }));
    };

    // Handle reset for personal information form
    const handleResetPersonalInfo = () => {
        setFormValues((prevValues) => ({
            ...prevValues!,
            first_name: accountDetails.first_name,
            last_name: accountDetails.last_name,
            email: accountDetails.email,
            phone_number: accountDetails.phone_number,
        }));
    };

    // Handle reset for integrations form
    const handleResetIntegrations = () => {
        setFormValues((prevValues) => ({
            ...prevValues!,
            amazon_store_name: accountDetails.amazon_store_name,
            inventory_labs_email: accountDetails.inventory_labs_email,
            inventory_labs_password: accountDetails.inventory_labs_password,
            boxem_email: accountDetails.boxem_email,
            boxem_password: accountDetails.boxem_password,
        }));
    };

    return (
        <div className="flex flex-col sm:px-10 px-4 flex-grow">
            <Header />
            <span className="text-2xl font-bold pt-10">Account Settings</span>
            <div className="space-y-10 divide-y divide-gray-900/10 py-10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-8">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Personal Information
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="first-name"
                                            name="first_name"
                                            type="text"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.first_name || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="last-name"
                                            name="last_name"
                                            type="text"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.last_name || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            disabled
                                            type="email"
                                            autoComplete="email"
                                            className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.email || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Phone number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="phone"
                                            name="phone_number"
                                            type="tel"
                                            autoComplete="phone"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.phone_number || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="button"
                                className="hover:text-gray-700 cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                                onClick={handleResetPersonalInfo} // Reset personal info form
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleUpdateAccountDetails("personal");
                                }}
                                className="h-10 cursor-pointer rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {sectionLoading === "personal" ? (
                                    <div className="flex flex-row items-center space-x-1">
                                        <LoadingWheel color="white" />{" "}
                                        <span>Save</span>
                                    </div>
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Integrations
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-full">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Amazon store name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="amazon-store-name"
                                            name="amazon_store_name"
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.amazon_store_name ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="inventory-labs-email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Inventory Labs email
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="inventory-labs-email"
                                            name="inventory_labs_email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.inventory_labs_email ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="inventory-labs-password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Inventory Labs password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="inventory-labs-password"
                                            name="inventory_labs_password"
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.inventory_labs_password ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="boxem-email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Boxem email
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="boxem-email"
                                            name="boxem_email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.boxem_email || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="boxem-password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Boxem password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="boxem-password"
                                            name="boxem_password"
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.boxem_password || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="button"
                                className="hover:text-color-gray-700 cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                                onClick={handleResetIntegrations} // Reset integrations form
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleUpdateAccountDetails("integrations");
                                }}
                                className="h-10 cursor-pointer rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {sectionLoading === "integrations" ? (
                                    <div className="flex flex-row items-center space-x-1">
                                        <LoadingWheel color="white" />{" "}
                                        <span>Save</span>
                                    </div>
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
