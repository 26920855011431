/** @format */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import Shipment from "../../../../../../models/shipments";
import OutboundOrdersModal from "../outbound-shipment/outboundOrders";
import SingleCalendar from "../../../../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";

interface ClientOutboundTableProps {
    shipments: Shipment[];
}

function ClientShipmentsTable({ shipments }: ClientOutboundTableProps) {
    const [searchParams] = useSearchParams();

    const [loading] = useState(false);

    const [openOutboundOrders, setOpenOutboundOrders] = useState(false);

    const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
        null
    );

    const [results] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    useEffect(() => {
        if (selectedShipment) {
            setOpenOutboundOrders(true);
        }
    }, [selectedShipment]);

    useEffect(() => {
        if (!openOutboundOrders) {
            setTimeout(() => {
                setSelectedShipment(null);
            }, 200);
        }
    }, [openOutboundOrders]);

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

    return (
        <div className="flex flex-col">
            {!loading && shipments && shipments.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    <th className="py-2 sm:min-w-44 min-w-44">
                                        Shipment Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Date
                                    </th>
                                    <th className="py-2 min-w-32">
                                        Small boxes
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-60">
                                        Medium boxes
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Large boxes
                                    </th>
                                    <th className="py-2 min-w-32">Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment) => (
                                    <tr
                                        onClick={() =>
                                            setSelectedShipment(shipment)
                                        }
                                        key={shipment.shipment_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-2.5">
                                            {shipment.name}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(shipment.date)}
                                        </td>
                                        <td className="">
                                            {shipment.small_boxes}
                                        </td>
                                        <td className="">
                                            {shipment.medium_boxes}
                                        </td>
                                        <td className="">
                                            {shipment.large_boxes}
                                        </td>
                                        <td className="">{shipment.notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing all results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "back"
                                )}`}
                                className={`${
                                    getOffset(searchParams) === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </a>
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "forward"
                                )}`}
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Next
                            </a>
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no outbound shipments
                    </span>
                </div>
            )}

            <OutboundOrdersModal
                shipment={selectedShipment}
                openOutboundOrders={openOutboundOrders}
                setOpenOutboundOrders={setOpenOutboundOrders}
                handleFullRefetch={() => {}}
            />
        </div>
    );
}

export default ClientShipmentsTable;
