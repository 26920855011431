/** @format */

import { useParams, useSearchParams } from "react-router-dom";
import { Order } from "../../../../../models/order";
import Results from "../../../../../models/results";
import { useEffect, useState } from "react";
import { useTrackingNumbersModal } from "../../../../../hooks/useTrackingNumbers";
import { useCheckInDatesModal } from "../../../../../hooks/useCheckInDates";
import { StatusTag } from "../../../../../components/statusTags";
import { formatDateFromDb } from "../../../../../utils/date/date_formatter";
import { formatMoney } from "../../../../../utils/price/formatMoney";
import { PaginationResults } from "../../../../../utils/pagination/paginationResults";
import TrackingNumbersModal from "../../../../../components/trackingNumbersModal";
import CheckInDatesModal from "../../../../../components/checkInDatesModal";
import { fetchClientOrders } from "../../api/unitsApi";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import LoadingWheel, {
    SortLoading,
} from "../../../../../components/loadingWheel";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import { capitalizeFirstLetter } from "../../../../../utils/strings/string";
import LimitSelector, {
    AdminLimitSelector,
} from "../../../../../utils/pagination/limitSelector";
import useScrollTranslation from "../../../../../hooks/useScrollTranslation";
import { sortableColumns, useSort } from "../../../../../hooks/useSort";
import ColumnSort from "../../../../../utils/sort/columnSort";

const tableHeaders = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
        sortable: true,
    },
    {
        key: "fulfilled_by",
        label: "Fulfilled by",
        className: "sm:min-w-32 min-w-32",
        sortable: true,
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    { key: "retailer", label: "Retailer", className: "min-w-32" },
    { key: "order_number", label: "Order #", className: "min-w-32" },
    { key: "item_name", label: "Item Name", className: "sm:min-w-48 min-w-48" },
    { key: "brand_sku", label: "Brand SKU", className: "sm:min-w-32 min-w-28" },
    { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
    { key: "quantity", label: "Quantity", className: "sm:min-w-32 min-w-28" },
    { key: "color", label: "Color", className: "sm:min-w-32 min-w-28" },
    { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28" },
    {
        key: "amazon_sku",
        label: "Amazon SKU",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "unit_cost",
        label: "Unit cost",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "list_price",
        label: "List price",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "trackings", label: "Trackings", className: "sm:min-w-32 min-w-28" },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

interface ClientInboundTableProps {
    inboundOrders: Order[];
    setInboundOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    inboundResults: Results;
    setInboundResults: React.Dispatch<React.SetStateAction<Results>>;
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    pageRefs: AdminClientTypesRefs;
}

function ClientInboundTable({
    inboundOrders,
    setInboundOrders,
    inboundResults,
    setInboundResults,
    selectedOrders,
    setSelectedOrders,
    pageRefs,
}: ClientInboundTableProps) {
    const [searchParams] = useSearchParams();
    const { user_id } = useParams();
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);

    const fetchData = async () => {
        const data = await fetchClientOrders(
            user_id,
            "inbound",
            undefined,
            offset,
            searchParams.get("limit") ? Number(searchParams.get("limit")) : 10,
            sortConfig
        );
        if (data.status === "success") {
            setInboundOrders(data.data.orders);
            setInboundResults(data.data.results);
        } else {
            showNotification("Failed fetching orders", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        pageRefs.inboundRef.current = true;
    };

    useEffect(() => {
        if (!pageRefs.inboundRef.current || offset !== 0 || limit !== 0) {
            setLoading(true);
        }
        fetchData();
    }, [offset, limit]);

    useEffect(() => {
        fetchData();
    }, [sortLoading]);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelectedOrders) {
            if (e.target.checked) {
                setSelectedOrders(inboundOrders);
            } else {
                // setAllClientsSelected(false);
                setSelectedOrders([]);
            }
        }
    };

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div className="flex flex-col pt-3">
            {!loading && inboundOrders && inboundOrders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {selectedOrders ? (
                                        <th className="py-2 min-w-10">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                onChange={handleSelectAll}
                                                checked={
                                                    selectedOrders &&
                                                    inboundOrders.length ===
                                                        selectedOrders.length &&
                                                    inboundOrders.length > 0
                                                }
                                            />
                                        </th>
                                    ) : (
                                        <></>
                                    )}
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {inboundOrders.map((order) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {selectedOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={selectedOrders.some(
                                                        (o) =>
                                                            o.order_id ===
                                                            order.order_id
                                                    )}
                                                />
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        <td className="py-2.5 pr-4 whitespace-nowrap">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {capitalizeFirstLetter(
                                                order.fulfilled_by
                                            )}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td className="">{order.quantity}</td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td className="">
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onClick={() =>
                                                handleViewTrackings(
                                                    order.trackings
                                                )
                                            }
                                            className="text-accent"
                                        >
                                            View
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {inboundResults ? (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <div className="flex flex-row items-center space-x-3">
                                    <PaginationResults
                                        results={inboundResults}
                                    />
                                    {/* <AdminLimitSelector
                                        limit={limit}
                                        setLimit={setLimit}
                                        setOffset={setOffset}
                                    /> */}
                                    <LimitSelector />
                                </div>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <button
                                    onClick={() =>
                                        setOffset(
                                            (prevOffset) => prevOffset - 1
                                        )
                                    }
                                    disabled={offset === 0}
                                    className={`${
                                        offset === 0
                                            ? "pointer-events-none text-gray-400 ring-gray-200"
                                            : "text-gray-900 ring-gray-300"
                                    } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() =>
                                        setOffset(
                                            (prevOffset) => prevOffset + 1
                                        )
                                    }
                                    disabled={
                                        inboundResults.showing_to >=
                                        inboundResults.total_results
                                    }
                                    className={`${
                                        inboundResults.showing_to >=
                                        inboundResults.total_results
                                            ? "pointer-events-none text-gray-400 ring-gray-200"
                                            : "text-gray-900 ring-gray-300"
                                    } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                                >
                                    Next
                                </button>
                            </div>
                        </nav>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no inbound orders
                        </span>
                    )}
                </div>
            )}

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
            />
        </div>
    );
}

export default ClientInboundTable;
