/** @format */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import Shipment from "../../../models/shipments";
import LoadingWheel, { SortLoading } from "../../../components/loadingWheel";
import { EmptyOutboundTable } from "../../../components/emptyStates";
import OutboundOrdersModal from "./outboundOrdersModal";
import Results from "../../../models/results";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import { ShipmentStatusTag } from "../../../components/statusTags";
import { ShipmentPageSelector } from "../../../models/pageSelectors";
import LimitSelector from "../../../utils/pagination/limitSelector";
import { ValidPageTypes } from "../../../hooks/usePage";
import useScrollTranslation from "../../../hooks/useScrollTranslation";
import ColumnSort from "../../../utils/sort/columnSort";
import { sortableColumns } from "../../../hooks/useSort";

const tableHeaders = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    { key: "small_boxes", label: "Small boxes", className: "min-w-32" },
    { key: "medium_boxes", label: "Medium boxes", className: "min-w-32" },
    {
        key: "large_boxes",
        label: "Large boxes",
        className: "min-w-32",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

const tableHeaders2 = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

function ShipmentsTable({
    shipments,
    results,
    loading,
    pageSelected,
    sortConfig,
    toggleSort,
    sortLoading,
}: {
    shipments: Shipment[];
    results: Results;
    loading: boolean;
    pageSelected: ValidPageTypes;
    sortConfig: {
        key: string;
        order: string;
    };
    toggleSort: (key: string) => void;
    sortLoading: boolean;
}) {
    const [searchParams] = useSearchParams();

    const [openOutboundOrders, setOpenOutboundOrders] = useState(false);

    const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
        null
    );

    useEffect(() => {
        if (selectedShipment) {
            setOpenOutboundOrders(true);
        }
    }, [selectedShipment]);

    useEffect(() => {
        if (!openOutboundOrders) {
            setTimeout(() => {
                setSelectedShipment(null);
            }, 200);
        }
    }, [openOutboundOrders]);

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const headers = pageSelected === "shipped" ? tableHeaders : tableHeaders2;

    return (
        <div className="flex flex-col">
            {!loading && shipments && shipments.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {headers.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment) => (
                                    <tr
                                        onClick={() =>
                                            setSelectedShipment(shipment)
                                        }
                                        key={shipment.shipment_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {pageSelected === "shipped" ? (
                                            <>
                                                <td className="py-2.5">
                                                    <ShipmentStatusTag
                                                        status={
                                                            shipment.admin_status
                                                                ? shipment.admin_status
                                                                : shipment.status
                                                        }
                                                    />
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.name}
                                                </td>
                                                <td className="py-2.5">
                                                    {formatDateFromDb(
                                                        shipment.date
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.small_boxes}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.medium_boxes}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.large_boxes}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.notes}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="py-2.5">
                                                    <ShipmentStatusTag
                                                        status={
                                                            shipment.admin_status
                                                                ? shipment.admin_status
                                                                : shipment.status
                                                        }
                                                    />
                                                </td>
                                                <td className="py-2.5">
                                                    {formatDateFromDb(
                                                        shipment.date
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.name}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.notes}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/outbound?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/outbound?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? <LoadingWheel /> : <EmptyOutboundTable />}
                </div>
            )}

            <OutboundOrdersModal
                shipmentLoaded={selectedShipment}
                openOutboundOrders={openOutboundOrders}
                setOpenOutboundOrders={setOpenOutboundOrders}
            />
        </div>
    );
}

export default ShipmentsTable;
