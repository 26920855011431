/** @format */
import { MdOutlineDelete } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideBar from "../../../../components/admin/sideBar";
import Header from "../../../../components/header";
import { useEffect, useRef, useState } from "react";
import { fetchClientData } from "../api/clientsApi";
import Client from "../../../../models/clients";
import LoadingWheel from "../../../../components/loadingWheel";
import { FaRegEdit } from "react-icons/fa";
import DeleteClientModal from "../actions/deleteClientModal";
import AccountDetails from "./client/accountDetails";
import TotalOrdersFilters from "../../../../models/filtersCount";
import { EditClientModal } from "../actions/editClientModal";
import PageSelector from "./pageSelector";
import AccountBilling from "./billing/accountBilling";
import { AddClientTrackingsModal } from "../actions/addClientTrackingsModal";
import { ClientPage } from "../../../../models/pageSelectors";
import AdminInboundPage from "./inbound/adminInboundPage";
import AdminInventoryPage from "./inventory/adminInventoryPage";
import AdminOutboundPage from "./outbound/adminOutboundPage";
import { AdminClientTypesRefs } from "../../../../models/refsModels";
import { Order } from "../../../../models/order";
import Results from "../../../../models/results";
import Shipment from "../../../../models/shipments";
import { getAdminType } from "../../../../utils/adminTypes/actions";

function InformationActions({
    client,
    setOpenEditClientModal,
    setOpenDeleteModal,
}: {
    client: Client | null;
    setOpenEditClientModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const isOwner = getAdminType() === "owner";

    const [openAddClientTrackingsModal, setOpenAddClientTrackingsModal] =
        useState(false);

    return (
        <div className="flex flex-col sm:flex-row justify-between sm:items-center pt-10">
            <div className="flex flex-col">
                <span className="text-2xl font-bold">
                    {client?.first_name} {client?.last_name}
                </span>
                <span className="text-xl font-regular text-gray-600">
                    {client?.email}
                </span>
            </div>
            <div className="flex flex-row space-x-4 pt-6 sm:pt-0">
                <button
                    onClick={() => setOpenAddClientTrackingsModal(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 border border-gray-400 shadow-sm rounded-md text-black"
                >
                    <FaRegEdit className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Scan trackings</span>
                </button>
                <button
                    onClick={() => setOpenEditClientModal(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 border border-gray-400 shadow-sm rounded-md text-black"
                >
                    <FaRegEdit className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Edit client</span>
                </button>
                {isOwner ? (
                    <button
                        onClick={() => setOpenDeleteModal(true)}
                        className="flex h-7 flex-row items-center text-xs px-2 bg-red-600 rounded-md text-white"
                    >
                        <MdOutlineDelete className="w-4 h-4" />{" "}
                        <span className="pl-1.5">Delete client</span>
                    </button>
                ) : null}
            </div>

            <AddClientTrackingsModal
                openAddClientTrackingsModal={openAddClientTrackingsModal}
                setOpenAddClientTrackingsModal={setOpenAddClientTrackingsModal}
            />
        </div>
    );
}

function ClientDataPage() {
    const { user_id } = useParams();
    const isOwner = getAdminType() === "owner";

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const [client, setClient] = useState<Client | null>(null);
    const [filtersCount, setFiltersCount] = useState<TotalOrdersFilters | null>(
        null
    );

    const [openEditClientModal, setOpenEditClientModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [refetchClientData, setRefetchClientData] = useState<number>(0);
    const handleClientRefetch = () => {
        setRefetchClientData((prevValue) => prevValue + 1);
    };

    const handleFetchClientData = async () => {
        setLoading(true);
        const data = await fetchClientData(user_id);
        if (data.status === "success") {
            setClient(data.data.client_data);
            setFiltersCount(data.data.orders_count);
        }
        setLoading(false);
    };

    useEffect(() => {
        handleFetchClientData();
    }, [refetchClientData]);

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (queryParams.get("page") as ClientPage) || "data";
    };

    const [pageSelected, setPageSelected] = useState<ClientPage>(
        getQueryParams()
    );

    useEffect(() => {
        const page = getQueryParams();
        setPageSelected(page);

        // Navigate to the correct page if URL is invalid
        if (
            page &&
            ["data", "inbound", "inventory", "outbound", "billing"].indexOf(
                page
            ) === -1
        ) {
            navigate(`/admin/clients/${user_id}?page=data`, { replace: true });
        }
    }, [location.search, navigate]);

    const handlePageChange = (newPage: ClientPage) => {
        setPageSelected(newPage);
        navigate(`/admin/clients/${user_id}?page=${newPage}`);
    };

    const pageRefs: AdminClientTypesRefs = {
        inboundRef: useRef(false),
        inventoryRef: useRef(false),
        shipmentRef: useRef(false),
    };

    const [inboundOrders, setInboundOrders] = useState<Order[]>([]);
    const [inboundResults, setInboundResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [inventoryOrders, setInventoryOrders] = useState<Order[]>([]);
    const [inventoryResults, setInventoryResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [shipmentResults, setShipmentResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    return (
        <div className="flex flex-row">
            <SideBar />
            <div className="flex flex-col w-full mx-4 sm:mx-10 overflow-x-auto">
                <Header />

                {!loading ? (
                    <div className="pb-32">
                        <InformationActions
                            client={client}
                            setOpenEditClientModal={setOpenEditClientModal}
                            setOpenDeleteModal={setOpenDeleteModal}
                        />

                        <PageSelector
                            pageSelected={pageSelected}
                            handlePageChange={handlePageChange}
                            filtersCount={filtersCount}
                        />

                        {pageSelected === "data" ? (
                            <AccountDetails client={client} />
                        ) : pageSelected === "inbound" ? (
                            <AdminInboundPage
                                pageRefs={pageRefs}
                                handleClientRefetch={handleClientRefetch}
                                inboundOrders={inboundOrders}
                                setInboundOrders={setInboundOrders}
                                inboundResults={inboundResults}
                                setInboundResults={setInboundResults}
                            />
                        ) : pageSelected === "inventory" ? (
                            <AdminInventoryPage
                                pageRefs={pageRefs}
                                handleClientRefetch={handleClientRefetch}
                                inventoryOrders={inventoryOrders}
                                setInventoryOrders={setInventoryOrders}
                                inventoryResults={inventoryResults}
                                setInventoryResults={setInventoryResults}
                            />
                        ) : pageSelected === "outbound" ? (
                            <AdminOutboundPage
                                pageRefs={pageRefs}
                                handleClientRefetch={handleClientRefetch}
                                shipments={shipments}
                                setShipments={setShipments}
                                shipmentResults={shipmentResults}
                                setShipmentResults={setShipmentResults}
                            />
                        ) : (
                            <>
                                {isOwner ? (
                                    <AccountBilling />
                                ) : (
                                    <div className="flex flex-row items-center justify-center h-full text-sm text-gray-600">
                                        You do not have access.
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-grow items-center h-screen justify-center pb-64">
                        <LoadingWheel />
                    </div>
                )}
            </div>

            <DeleteClientModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                selectedClients={[user_id ?? ""]}
                mode="single"
                allClientsSelected={false}
                handleRefetch={handleClientRefetch}
            />

            <EditClientModal
                client={client}
                openEditClientModal={openEditClientModal}
                setOpenEditClientModal={setOpenEditClientModal}
                handleRefetch={handleClientRefetch}
            />
        </div>
    );
}

export default ClientDataPage;
