/** @format */

import { useEffect, useState } from "react";
import ClientInboundTable from "./inboundOrdersTable";
import Results from "../../../../../models/results";
import { Order } from "../../../../../models/order";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import ModifyInboundOrderModal from "../shipments/inbound-management/modifyInboundOrderModal";
import LoadingWheel from "../../../../../components/loadingWheel";
import { InboundPageType } from "../../../../../models/pageSelectors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteClientOrders } from "../../api/unitsApi";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import AccountTrackings from "../trackings/accountTrackings";
import { getAdminType } from "../../../../../utils/adminTypes/actions";

const selectorsData = [
    { label: "Inbound", value: "units" },
    { label: "Trackings", value: "trackings" },
];

interface AdminInboundPageProps {
    pageRefs: AdminClientTypesRefs;
    handleClientRefetch: () => void;
    inboundOrders: Order[];
    setInboundOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    inboundResults: Results;
    setInboundResults: React.Dispatch<React.SetStateAction<Results>>;
}

function AdminInboundPage({
    pageRefs,
    handleClientRefetch,
    inboundOrders,
    setInboundOrders,
    inboundResults,
    setInboundResults,
}: AdminInboundPageProps) {
    const isOwner = getAdminType() === "owner";
    const { user_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { showNotification } = useNotification();

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [openModifyInboundOrder, setOpenModifyInboundOrder] = useState(false);

    const [deleteLoading, setDeleteLoading] = useState(false);

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (queryParams.get("sub_page") as InboundPageType) || "units";
    };

    const [pageSelected, setPageSelected] = useState<InboundPageType>(
        getQueryParams()
    );

    useEffect(() => {
        const page = getQueryParams();
        setPageSelected(page);
        // Navigate to the correct page if URL is invalid
        if (page && ["units", "trackings"].indexOf(page) === -1) {
            navigate(`/admin/clients/${user_id}?page=inbound`, {
                replace: true,
            });
        }
    }, [location.search, navigate]);

    const handlePageChange = (newPage: InboundPageType) => {
        setPageSelected(newPage);
        navigate(`/admin/clients/${user_id}?page=inbound&sub_page=${newPage}`);
        setSelectedOrders([]);
    };

    const handleDelete = async () => {
        setDeleteLoading(true);
        const data = await deleteClientOrders(user_id, selectedOrders);
        if (data.status === "success") {
            handleClientRefetch();
            showNotification("Deleted order.", "", "success");
        } else {
            showNotification("Failed to delete orders", data.message, "error");
        }
        setDeleteLoading(false);
    };

    return (
        <div>
            <div className="flex flex-col">
                <div className="flex flex-row items-start justify-between pt-8 relative">
                    <div className="flex flex-row font-semibold relative pb-2">
                        {selectorsData.map((item) => (
                            <button
                                className={`relative text-sm ${
                                    pageSelected === item.value
                                        ? "text-accent after:bg-purple-500"
                                        : "text-gray-400 after:bg-transparent"
                                } w-28 after:absolute after:left-0 after:bottom-[-10px] after:h-0.5 after:w-full`}
                                onClick={() =>
                                    handlePageChange(
                                        item.value as InboundPageType
                                    )
                                }
                            >
                                {item.label}
                            </button>
                        ))}
                    </div>

                    <div className="absolute right-0 bottom-2 flex space-x-2">
                        {isOwner ? (
                            <>
                                {selectedOrders ? (
                                    <div
                                        className={`transition-opacity duration-300 ease-in-out ${
                                            selectedOrders.length > 0
                                                ? "opacity-100"
                                                : "opacity-0 pointer-events-none"
                                        }`}
                                    >
                                        <button
                                            onClick={() => handleDelete()}
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 h-7 text-xs font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                        >
                                            {deleteLoading ? (
                                                <LoadingWheel
                                                    color="white"
                                                    size="small"
                                                />
                                            ) : (
                                                <>Delete</>
                                            )}
                                        </button>
                                    </div>
                                ) : null}
                            </>
                        ) : null}

                        <button
                            disabled={selectedOrders.length === 0}
                            onClick={() => setOpenModifyInboundOrder(true)}
                            className={`${
                                selectedOrders.length === 0
                                    ? "bg-accentdim"
                                    : "bg-accent hover:bg-accenthighlight"
                            } text-white font-semibold px-5 text-xs rounded-md h-7`}
                        >
                            Modify Shipment Status
                        </button>
                    </div>
                </div>
                <div className="w-full h-0.5 bg-gray-300"></div>

                {pageSelected === "units" ? (
                    <ClientInboundTable
                        inboundOrders={inboundOrders}
                        setInboundOrders={setInboundOrders}
                        inboundResults={inboundResults}
                        setInboundResults={setInboundResults}
                        selectedOrders={selectedOrders}
                        setSelectedOrders={setSelectedOrders}
                        pageRefs={pageRefs}
                    />
                ) : (
                    <AccountTrackings type="inbound" />
                )}
            </div>

            <ModifyInboundOrderModal
                selectedInboundOrders={selectedOrders}
                openModifyInboundOrder={openModifyInboundOrder}
                setOpenModifyInboundOrder={setOpenModifyInboundOrder}
                handleFullRefetch={handleClientRefetch}
            />
        </div>
    );
}

export default AdminInboundPage;
