import Cookies from "js-cookie";


export const fetchSettings = async () => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/settings`;
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const addAdminAccount = async (email: string, password: string) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/settings/account`;
        const response = await fetch(
            url,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({email, password})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const deleteAdminAccount = async (email_to_delete: string) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/settings/account`;
        const response = await fetch(
            url,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({email_to_delete})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const updateAddress = async (
    street1: string,
    street2: string,
    city: string,
    zip: string,
    state: string
) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/settings/address`;
        const response = await fetch(
            url,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    street1,
                    street2,
                    city,
                    zip,
                    state
                })
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 
