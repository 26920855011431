/** @format */

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShipmentPageSelector } from "../../../../../models/pageSelectors";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import Results from "../../../../../models/results";
import Shipment from "../../../../../models/shipments";
import ClientShipmentsTable from "./shipmentsTable";
import { useEffect, useState } from "react";

const selectorsData = [
    { label: "Outbound", value: "shipped" },
    { label: "Requested", value: "requested" },
    { label: "Trackings", value: "trackings" },
];

interface AdminOutboundPageProps {
    pageRefs: AdminClientTypesRefs;
    handleClientRefetch: () => void;
    shipments: Shipment[];
    setShipments: React.Dispatch<React.SetStateAction<Shipment[]>>;
    shipmentResults: Results;
    setShipmentResults: React.Dispatch<React.SetStateAction<Results>>;
}

function AdminOutboundPage({
    pageRefs,
    handleClientRefetch,
    shipments,
    setShipments,
    shipmentResults,
    setShipmentResults,
}: AdminOutboundPageProps) {
    const { user_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (
            (queryParams.get("sub_page") as ShipmentPageSelector) || "shipped"
        );
    };

    const [pageSelected, setPageSelected] = useState<ShipmentPageSelector>(
        getQueryParams()
    );

    useEffect(() => {
        const page = getQueryParams();
        setPageSelected(page);
        // Navigate to the correct page if URL is invalid
        if (
            page &&
            ["shipped", "requested", "trackings"].indexOf(page) === -1
        ) {
            navigate(`/admin/clients/${user_id}?page=outbound`, {
                replace: true,
            });
        }
    }, [location.search, navigate]);

    const handlePageChange = (newPage: ShipmentPageSelector) => {
        setPageSelected(newPage);
        navigate(`/admin/clients/${user_id}?page=outbound&sub_page=${newPage}`);
    };

    return (
        <div>
            <div className="flex flex-row font-semibold pb-2 relative pt-8">
                {selectorsData.map((item) => (
                    <button
                        className={`relative text-sm ${
                            pageSelected === item.value
                                ? "text-accent after:bg-purple-500"
                                : "text-gray-400 after:bg-transparent"
                        } w-28 after:absolute after:left-0 after:bottom-[-10px] after:h-0.5 after:w-full`}
                        onClick={() =>
                            setPageSelected(item.value as ShipmentPageSelector)
                        }
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <div className="w-full h-0.5 bg-gray-300"></div>
            <ClientShipmentsTable
                shipments={shipments}
                setShipments={setShipments}
                shipmentResults={shipmentResults}
                setShipmentResults={setShipmentResults}
                pageRefs={pageRefs}
                handleFullRefetch={handleClientRefetch}
                pageSelected={pageSelected}
            />
        </div>
    );
}
export default AdminOutboundPage;
