/** @format */

import { ValidPageTypes } from "../../../hooks/usePage";
import {
    InventoryPageType,
    ShipmentPageSelector,
} from "../../../models/pageSelectors";

function OutboundPageSelector({
    pageSelected,
    handlePageChange,
}: {
    pageSelected: ValidPageTypes;
    handlePageChange: (newPage: ShipmentPageSelector) => void;
}) {
    const selectorsData = [
        { label: "Outbound", value: "shipped" },
        { label: "Requested", value: "requested" },
        { label: "Trackings", value: "trackings" },
    ];

    return (
        <div className="flex flex-col pt-10">
            <div className="flex flex-row font-semibold pb-2 relative">
                {selectorsData.map((item) => (
                    <button
                        className={`relative ${
                            pageSelected === item.value
                                ? "text-accent after:bg-purple-500"
                                : "text-gray-400 after:bg-transparent"
                        } w-32 after:absolute after:left-0 after:bottom-[-12px] after:h-1 after:w-full`}
                        onClick={() =>
                            handlePageChange(item.value as ShipmentPageSelector)
                        }
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <div className="w-full h-1 bg-gray-300"></div>
        </div>
    );
}

export default OutboundPageSelector;
