/** @format */

import {
    PlusIcon,
    PencilSquareIcon,
    PlusCircleIcon,
} from "@heroicons/react/24/outline";
import DashboardStats from "../../../models/dashboardStats";
import { formatMoney } from "../../../utils/price/formatMoney";
import { useRef, useState } from "react";
import { IoAddOutline, IoClose } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { TaxPopup } from "./taxPopup";
import { updateTax } from "./api/dashboardApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { WarehouseAddress } from "../../../models/adminDashboard";

export default function OverviewCards({
    dashboardOverviewStats,
    warehouseAddress,
    handleRefetch,
}: {
    dashboardOverviewStats: DashboardStats | null;
    warehouseAddress: WarehouseAddress | null;
    handleRefetch: () => void;
}) {
    const taxButtonRef = useRef<HTMLButtonElement>(null);

    const [tax, setTax] = useState("");

    const [isTaxOpen, setIsTaxOpen] = useState<boolean>(false);

    const { showNotification } = useNotification();

    const toggleTax = () => {
        setIsTaxOpen((prevState) => !prevState);
    };

    const [taxLoading, setTaxLoading] = useState(false);

    const handleUpdateTax = async () => {
        setTaxLoading(true);
        const data = await updateTax(tax);
        if (data.status === "success") {
            handleRefetch();
            toggleTax();
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setTaxLoading(false);
    };

    return (
        <div>
            <div className="flex flex-col sm:flex-row pt-4 sm:space-x-10 sm:space-y-0 space-y-4">
                <div className="bg-gray-50 sm:rounded-lg w-full h-52">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Shipment Address
                        </h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <div className="flex flex-col xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">Name:</p>
                                <p className="xl:text-right">
                                    FirstName LastName
                                </p>
                            </div>
                            <div className="flex flex-col xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">
                                    Street1:
                                </p>
                                <p>{warehouseAddress?.street1}</p>
                            </div>
                            <div className="flex xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">
                                    Street2:
                                </p>
                                <p>{warehouseAddress?.street2}</p>
                            </div>
                            <div className="flex flex-col xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">City:</p>
                                <p>{warehouseAddress?.city}</p>
                            </div>
                            <div className="flex flex-col xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">Zip:</p>
                                <p>{warehouseAddress?.zip}</p>
                            </div>
                            <div className="flex flex-col xl:flex-row ">
                                <p className="xl:w-32 hidden xl:block">
                                    State:
                                </p>
                                <p>{warehouseAddress?.state}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 sm:rounded-lg w-full">
                    <div className="px-4 py-5 sm:p-6 flex justify-between flex-col h-full">
                        <div className="">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                Total spent
                            </h3>

                            <div className="text-xl pt-4">
                                {formatMoney(
                                    dashboardOverviewStats?.total_spent
                                )}
                            </div>
                        </div>

                        <div className="text-sm text-gray-500 pt-4">
                            On inventory
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 sm:rounded-lg w-full relative">
                    <div className="px-4 py-5 sm:p-6 flex justify-between flex-col h-full">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                Total saved
                            </h3>
                            {dashboardOverviewStats?.tax_rate ? (
                                <div className="text-xl pt-4">
                                    {formatMoney(
                                        dashboardOverviewStats?.total_saved
                                    )}
                                </div>
                            ) : (
                                <div className="relative">
                                    <button
                                        ref={taxButtonRef}
                                        onClick={toggleTax}
                                        className="flex flex-row items-center pt-4 text-accent text-base"
                                    >
                                        <PlusCircleIcon className="w-5 h-5 pt-0.5" />
                                        <span className="pl-1">
                                            Add your tax rate
                                        </span>
                                    </button>
                                    <TaxPopup
                                        isOpen={isTaxOpen}
                                        onClose={() => setIsTaxOpen(false)}
                                        setTax={setTax}
                                        tax={tax}
                                        taxButtonRef={taxButtonRef}
                                        handleUpdateTax={handleUpdateTax}
                                        taxLoading={taxLoading}
                                        type="set"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="text-sm text-gray-500 pt-4">
                            {dashboardOverviewStats?.tax_rate
                                ? "Saved in sales tax by shipping to our warehouse"
                                : "Add your state's tax rate to see your savings."}
                        </div>
                    </div>
                    {dashboardOverviewStats?.tax_rate ? (
                        <div className="absolute top-3 right-3">
                            <div className="relative">
                                <button
                                    ref={taxButtonRef}
                                    onClick={toggleTax}
                                    className="text-sm text-accent flex flex-row items-center"
                                >
                                    <PencilSquareIcon className="w-4 h-4" />{" "}
                                    <span className="pl-1">
                                        Change tax rate
                                    </span>
                                </button>
                                <TaxPopup
                                    isOpen={isTaxOpen}
                                    onClose={() => setIsTaxOpen(false)}
                                    setTax={setTax}
                                    tax={tax}
                                    taxButtonRef={taxButtonRef}
                                    handleUpdateTax={handleUpdateTax}
                                    taxLoading={taxLoading}
                                    type="edit"
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="flex flex-col sm:flex-row pt-4 sm:space-x-10 sm:space-y-0 space-y-4">
                <div className="w-full border border-dashed h-52 flex flex-col items-center justify-center">
                    <span className="text-sm font-semibold">
                        Inventory Labs Credentials
                    </span>
                    <p className="mt-1 text-sm text-gray-500">
                        Add your inventory labs credentials.
                    </p>
                    <div className="pt-3"></div>
                    <a href="/account">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-accent px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-accenthighlight "
                        >
                            <PlusIcon
                                aria-hidden="true"
                                className="-ml-0.5 mr-1.5 h-4 w-4"
                            />
                            Add credentials
                        </button>
                    </a>
                </div>
                <div className="w-full border border-dashed h-52 flex flex-col items-center justify-center">
                    <span className="text-sm font-semibold">
                        Boxem Credentials
                    </span>
                    <p className="mt-1 text-sm text-gray-500">
                        Add your boxem credentials.
                    </p>
                    <div className="pt-3"></div>
                    <a href="/account">
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-accent px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-accenthighlight "
                        >
                            <PlusIcon
                                aria-hidden="true"
                                className="-ml-0.5 mr-1.5 h-4 w-4"
                            />
                            Add credentials
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
