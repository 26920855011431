/** @format */

import Cookies from "js-cookie";

interface CheckAuthResult {
    authenticated: boolean;
    clientType: string;
}

export const checkAuth = async (): Promise<CheckAuthResult> => {
    try {
        const jwt_token = Cookies.get("jwt");
        if (!jwt_token) {
            return { authenticated: false, clientType: "" };
        }
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/check-auth`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${jwt_token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const data = await response.json();

        return {
            authenticated: data.status === "success",
            clientType: data.data.client_type,
        };
    } catch (error) {
        return { authenticated: false, clientType: "" };
    }
};
