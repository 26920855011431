import Cookies from "js-cookie"
import Results from "../../../../models/results"
import { buildUrl } from "../../../../utils/url/buildUrl";
import { SortConfig } from "../../../../hooks/useSort";


export const fetchInventoryOrders = async (searchParams: URLSearchParams, sortConfig: SortConfig) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/inventory`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            sort_key: sortConfig.key,
            sort_order: sortConfig.order,
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}

export const updateInventoryOrder = async (
    inventoryId: string | undefined,
    asin: string,
    amazonSKU: string,
    listPrice: string,
    fulfilledBy: string
) => {
    try {
        const jsonBody = {
            "asin": asin,
            "amazon_sku": amazonSKU,
            "list_price": listPrice,
            "fulfilled_by": fulfilledBy
        }
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inventory/" + inventoryId,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
                body: JSON.stringify(jsonBody)
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}

export const requestOutboundShipment = async (
    order_id: string,
    quantity_shipped: string,
    shipment_name: string,
    shipment_notes: string,
    used_labels: { [labelId: string]: number[]; }
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/outbound/request",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt") 
                },
                body: JSON.stringify({order_id, quantity_shipped, used_labels, shipment_name, shipment_notes})
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}


