import Cookies from "js-cookie";
import { ScannedBarcode } from "../../../../models/scannedBarcode";
import { buildUrl } from "../../../../utils/url/buildUrl";

export const fetchTrackings = async (
    user_id: string | undefined,
    offset: number,
    limit: number,
    type: string | undefined
) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/trackings/${user_id}`;
        const params = {
            offset: String(offset),
            limit: limit && limit != 0 ? String(limit) : "10",
            type: type ? type : 'inventory'
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}

export const addTrackingToClient = async (
    user_id: string | undefined,
    newScannedBarcode: ScannedBarcode
) => {
    try {
        const jsonBody = {
            user_id: user_id,
            tracking_id: String(newScannedBarcode.id),
            date: newScannedBarcode.dateScanned,
            tracking: newScannedBarcode.barcode
        }
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/trackings`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}

export const deleteTrackingFromClient = async (
    id: string | null,
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/trackings/${id}`,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}