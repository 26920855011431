import React, { useEffect, useState } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import Cookies from "js-cookie";
import LoadingWheel from "../../components/loadingWheel";
import {
    getAuthData,
    setAuthData,
    isAuthDataValid,
    clearAuthData,
    AuthData,
} from "./authUtils";

const STORAGE_KEY = "auth_data";

interface PrivateRouteProps {
    element: React.ComponentType<any>;
    allowedRoles: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    element: Component,
    allowedRoles,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<string | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const authData = getAuthData();
                if (authData && isAuthDataValid(authData)) {
                    setIsAuthenticated(authData.isAuthenticated);
                    setUserRole(authData.userRole);
                    setIsLoading(false);
                    return;
                }

                const jwt_token = Cookies.get("jwt");
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/api/v1/check-auth`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();
                if (data.status === "success") {
                    setIsAuthenticated(true);
                    setUserRole(data.data.client_type);
                    setAuthData({
                        isAuthenticated: true,
                        userRole: data.data.client_type,
                        timestamp: new Date().getTime(),
                    });
                } else {
                    setIsAuthenticated(false);
                    localStorage.removeItem(STORAGE_KEY);
                }
            } catch (error) {
                setIsAuthenticated(false);
                localStorage.removeItem(STORAGE_KEY);
            } finally {
                setIsLoading(false);
            }
        };
        checkAuth();
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-row justify-center items-center w-full h-screen">
                <LoadingWheel />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (isAuthenticated && !allowedRoles.includes(userRole || "")) {
        return <Navigate to="/unauthorized" />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;
