
export function buildUrl(baseUrl: string, params: { [key: string]: string | null }): string {
    const queryParams = [];

    // Loop through each param key-value pair
    for (const key in params) {
        const value = params[key];
        if (value) {
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }

    // Join the query parameters with '&' and prepend with '?'
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    // Return the full URL
    return `${baseUrl}${queryString}`;
}
