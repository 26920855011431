/** @format */

import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Client from "../models/clients";
import { searchClients } from "../pages/admin/3plclients/api/clientsApi";

interface HeaderSearchBarProps {
    setClients: React.Dispatch<React.SetStateAction<Client[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}

export default function HeaderSearchBar({
    setClients,
    setLoading,
    handleRefetch,
}: HeaderSearchBarProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        searchClients(searchTerm, setClients, setLoading, handleRefetch);
        return () => searchClients.cancel();
    }, [searchTerm]);

    return (
        <div className="relative w-full sm:w-96">
            <div className="group">
                <div className="group flex flex-row items-center space-x-3 px-2 h-8 rounded-md hover:bg-gray-100 focus-within:bg-gray-100 w-full">
                    <FaSearch className="text-gray-400 w-2.5 h-2.5 ml-1" />
                    <input
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="outline-none px-0 py-0 text-xs bg-transparent h-8 w-full border-transparent focus:border-transparent focus:ring-0"
                        ref={inputRef} // Attach the ref to the input
                    />
                </div>
                {/* <div className="max-h-64 overflow-y-auto mt-1 absolute py-1 w-full bg-white border shadow-md rounded-md z-50 text-xs group-focus-within:block hidden">
                    {searchTerm.length > 0 ? (
                        <div>
                            <ul>
                                <li className="px-4 py-2 text-gray-500">
                                    Clients:
                                </li>
                            </ul>
                            <ul>
                                {clients.length > 0 ? (
                                    clients.map((client, index) => (
                                        <>
                                            <li
                                                key={index}
                                                className="px-4 py-2 hover:bg-gray-100 text-gray-700 hover:text-gray-900 cursor-pointer"
                                            >
                                                {client.email}
                                            </li>
                                        </>
                                    ))
                                ) : (
                                    <li className="px-4 py-2 text-gray-500">
                                        No results
                                    </li>
                                )}
                            </ul>
                        </div>
                    ) : (
                        <div>
                            <div className="px-4 py-2 text-gray-700">
                                Search Filters
                            </div>
                            <div className="px-4 py-2 text-gray-600 text-xs">
                                Via email, name, phone, id
                            </div>
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );
}
