/** @format */

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { RxAvatar } from "react-icons/rx";
import { handleLogout } from "../utils/auth/handleLogout";
import { useNavigate } from "react-router-dom";

export default function Settings({ isMinimized }: { isMinimized?: boolean }) {
    const navigate = useNavigate();

    return (
        <Menu as="div" className="relative text-left z-50">
            <div className="z-50">
                <MenuButton className="w-full flex-grow">
                    <div className="hover:bg-gray-100 hover:text-accent group cursor-pointer text-gray-700 flex flex-row items-center space-x-3 rounded-md p-2">
                        <RxAvatar
                            className={`h-6 w-6 shrink-0 group-hover:text-accent
                                    ? "text-accent"
                                    : "text-gray-500"
                            }`}
                        />
                        {!isMinimized ? (
                            <span className="text-sm font-semibold">
                                Account
                            </span>
                        ) : null}
                    </div>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-50 left-0 w-56 origin-bottom-left bottom-full mb-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <MenuItem>
                        <a
                            href="/account"
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            Account settings
                        </a>
                    </MenuItem>
                    <MenuItem>
                        <button
                            type="submit"
                            onClick={() => handleLogout(navigate)}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            Sign out
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    );
}
