/** @format */

import { useSearchParams } from "react-router-dom";
import LoadingWheel, { SortLoading } from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../utils/pagination/calculateOffset";
import { EmptyInventoryTable } from "../../../../components/emptyStates";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { StatusTag } from "../../../../components/statusTags";
import { formatDateFromDb } from "../../../../utils/date/date_formatter";
import "../../../../index.css";
import { PaginationLink } from "../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../utils/pagination/paginationResults";
import { useEffect, useState } from "react";
import { EditInventoryOrderModal } from "./editInventoryOrder";
import { useCheckInDatesModal } from "../../../../hooks/useCheckInDates";
import CheckInDatesModal from "../../../../components/checkInDatesModal";
import { Order } from "../../../../models/order";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import LimitSelector from "../../../../utils/pagination/limitSelector";
import ColumnSort from "../../../../utils/sort/columnSort";
import useScrollTranslation from "../../../../hooks/useScrollTranslation";
import { sortableColumns } from "../../../../hooks/useSort";

const tableHeaders = [
    { key: "status", label: "Status", className: "sm:min-w-32 min-w-32" },
    {
        key: "fulfilled_by",
        label: "Fulfilled by",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "remaining_quantity",
        label: "Remaining Quantity",
        className: "sm:min-w-44 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    { key: "retailer", label: "Retailer", className: "min-w-32" },
    { key: "order_number", label: "Order #", className: "min-w-32" },
    {
        key: "item_name",
        label: "Item Name",
        className: "sm:min-w-48 min-w-48",
    },
    {
        key: "brand_sku",
        label: "Brand SKU",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
    {
        key: "quantity",
        label: "Quantity",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "color", label: "Color", className: "sm:min-w-32 min-w-28" },
    { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28" },
    {
        key: "amazon_sku",
        label: "Amazon SKU",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "unit_cost",
        label: "Unit cost",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "list_price",
        label: "List price",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

export default function InventoryTable({
    inventoryOrders,
    results,
    loading,
    selectedOrders,
    setSelectedOrders,
    handleRefetch,
    sortConfig,
    toggleSort,
    sortLoading,
}: {
    inventoryOrders: Order[];
    results?: Results;
    loading?: boolean;
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    handleRefetch: () => void;
    sortConfig?: {
        key: string;
        order: string;
    };
    toggleSort?: (key: string) => void;
    sortLoading?: boolean;
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedInventoryOrder, setSelectedInventoryOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInventoryOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInventoryOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInventoryOrder(null);
        }
    }, [openEditModal]);

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders([]);
            } else {
                // Add the order using its id
                setSelectedOrders([order]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div>
            {!loading && inventoryOrders && inventoryOrders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading || false}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead>
                                <tr className="text-left text-sm">
                                    {selectedOrders ? (
                                        <th
                                            key={"checkbox"}
                                            className={`py-2 min-w-10`}
                                        ></th>
                                    ) : null}
                                    {tableHeaders.map((header) => (
                                        <th
                                            onClick={() =>
                                                toggleSort &&
                                                toggleSort(header.key)
                                            }
                                            key={header.key}
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}{" "}
                                            {sortConfig && (
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {inventoryOrders.map((order) => (
                                    <tr
                                        onClick={
                                            selectedOrders
                                                ? () =>
                                                      setSelectedInventoryOrder(
                                                          order
                                                      )
                                                : () => {}
                                        }
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {selectedOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={
                                                        selectedOrders &&
                                                        selectedOrders.some(
                                                            (o) =>
                                                                o.order_id ===
                                                                order.order_id
                                                        )
                                                    }
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                />
                                            </td>
                                        ) : null}
                                        <td className="py-2.5 pr-4 whitespace-nowrap">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {capitalizeFirstLetter(
                                                order.fulfilled_by
                                            )}
                                        </td>
                                        <td className="">
                                            {order.quantity_remaining}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td className="">{order.quantity}</td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td className="">
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {results ? (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <div className="flex flex-row items-center space-x-3">
                                    <PaginationResults results={results} />
                                    <LimitSelector />
                                </div>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the previous page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "back"
                                            ).toString()
                                        );
                                        return `/inventory?${params.toString()}`;
                                    })()}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the next page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "forward"
                                            ).toString()
                                        );
                                        return `/inventory?${params.toString()}`;
                                    })()}
                                    disabled={
                                        results.showing_to >=
                                        results.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    ) : null}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? <LoadingWheel /> : <EmptyInventoryTable />}
                </div>
            )}

            <EditInventoryOrderModal
                inventoryOrder={selectedInventoryOrder}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
            />
        </div>
    );
}
