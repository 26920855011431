/** @format */

import { useState, useEffect, useCallback } from "react";
import { CheckInDate } from "../models/checkInDate";
import { Order } from "../models/order";

export function useCheckInDatesModal() {
    const [openCheckInDatesModal, setOpenCheckInDatesModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    useEffect(() => {
        if (selectedOrder) {
            setOpenCheckInDatesModal(true);
        }
    }, [selectedOrder]);

    useEffect(() => {
        if (!openCheckInDatesModal) {
            setTimeout(() => {
                setSelectedOrder(null);
            }, 250);
        }
    }, [openCheckInDatesModal]);

    const handleViewDates = useCallback((order: Order) => {
        setSelectedOrder(order);
    }, []);

    return {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    };
}
