/** @format */

import { useEffect, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import Settings from "../settings";
import { MdOutlineInventory2 } from "react-icons/md";
import { TbBus } from "react-icons/tb";
import { PiShippingContainer } from "react-icons/pi";
import { LuLayoutDashboard } from "react-icons/lu";

const navigation = [
    { name: "Dashboard", href: "/", icon: LuLayoutDashboard },
    { name: "Inbound", href: "/inbound", icon: TbBus },
    { name: "Inventory", href: "/inventory", icon: MdOutlineInventory2 },
    { name: "Outbound", href: "/outbound", icon: PiShippingContainer },
];

interface ClientMobileSideBarProps {
    hidden: boolean;
    toggleSidebar: () => void;
}

export default function ClientMobileSideBar({
    hidden,
    toggleSidebar,
}: ClientMobileSideBarProps) {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        var newNormalizedPath =
            normalizedPath.split("/")[0] +
            "/" +
            normalizedPath.split("/")[1] +
            "/" +
            normalizedPath.split("/")[2];
        setCurrentPath(newNormalizedPath);
    }, []);

    return (
        <div
            className={`flex-col items-start mb-5 bg-white fixed top-0 left-0 h-full z-40 transform ${
                hidden ? "-translate-x-full" : "translate-x-0"
            } transition-transform duration-300 rounded-r-xl`}
        >
            <div className="flex flex-row">
                {/* Apply fixed position */}
                <div className="fixed top-0 w-64 h-screen px-3 sm:px-9 pt-3 sm:pt-5 flex flex-col justify-between">
                    <div>
                        <button onClick={toggleSidebar}>
                            <HiMiniXMark className="h-7 w-7" />
                        </button>
                        <div className="pt-10"></div>
                        {navigation.map((item) => (
                            <div className="pt-1" key={item.name}>
                                <a
                                    href={item.href}
                                    className={`${
                                        currentPath === item.href
                                            ? "bg-gray-100 text-accent"
                                            : "hover:bg-gray-100 hover:text-accent group text-gray-700"
                                    } flex flex-row items-center space-x-3 rounded-md p-2`}
                                >
                                    <item.icon
                                        className={`h-6 w-6 shrink-0 group-hover:text-accent ${
                                            currentPath === item.href
                                                ? "text-accent"
                                                : "text-gray-500"
                                        }`}
                                    />
                                    <span className="text-sm font-semibold">
                                        {item.name}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div className="pb-4">
                        <Settings />
                    </div>
                </div>
                {/* Add margin to content to account for the fixed sidebar */}
                <div className="ml-64">
                    <div className="h-screen fixed w-px bg-gray-300"></div>
                </div>
            </div>
        </div>
    );
}
